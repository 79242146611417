import { useState, useEffect } from "react";

import { Row, Col, Form } from "react-bootstrap";

import { urlApiGeo } from "../../../config/constants";

function Step2({ formData, setFormData }) {
  const [provincias, setProvincias] = useState([]);
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [ciudades, setCiudades] = useState([]);

  const getCities = async (provinciaSeleccionada) => {
    const url =
      urlApiGeo +
      `localidades?provincia=${provinciaSeleccionada}&campos=nombre&max=2000`;
    const response = await fetch(url)
      .then((response) => response.json())
      .then((data) => setCiudades(data.localidades));
  };

  useEffect(async () => {
    const url = urlApiGeo + "provincias";
    const response = await fetch(url)
      .then((response) => response.json())
      .then((data) => setProvincias(data.provincias));
  }, []);

  const handleProvincia = (event) => {
    setFormData({ ...formData, provincia: event.target.value });
    setProvinciaSeleccionada(event.target.value);
    getCities(event.target.value);
  };
  return (
    <div>
      <Form.Group className="mb-3" controlId="formBasicStreet">
        <Form.Label>Calle</Form.Label>
        <Form.Control
          type="text"
          placeholder="Calle"
          required
          name="calle"
          value={formData.calle}
          onChange={(event) =>
            setFormData({ ...formData, calle: event.target.value })
          }
        />
        <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Form.Group as={Col} className="mb-3" controlId="formBasicNumberStreet">
          <Form.Label>Nro.</Form.Label>
          <Form.Control
            type="text"
            required
            name="numero"
            placeholder="Número"
            value={formData.numero}
            onChange={(event) =>
              setFormData({ ...formData, numero: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formBasicPostalCode">
          <Form.Label>Código Postal</Form.Label>
          <Form.Control
            type="text"
            placeholder="0000"
            required
            name="codigoPostal"
            value={formData.codigoPostal}
            onChange={(event) =>
              setFormData({ ...formData, codigoPostal: event.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Col>
          <Form.Label>País</Form.Label>
          <Form.Select
            className="mb-3"
            aria-label="Pais"
            required
            name="pais"
            placeholder="País"
            value={"Argentina"}
            // onChange={(event) =>
            //     // setFormData({...formData, pais: event.target.value})
            // }
          >
            <option value="Argentina">Argentina</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Label>Provincia</Form.Label>
          <Form.Select
            className="mb-3"
            aria-label="Provincia"
            required
            name="provincia"
            placeholder="provincia"
            value={formData.provincia}
            onChange={(event) => handleProvincia(event)}
          >
            <option selected value="">
              Selecciona una provincia
            </option>
            {provincias.map((provincia, index) => {
              return (
                <option key={index} value={provincia.nombre}>
                  {provincia.nombre}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label>Ciudad</Form.Label>
          <Form.Select
            aria-label="Ciudad"
            required
            name="ciudad"
            placeholder="Ciudad"
            value={formData.ciudad}
            onChange={(event) =>
              setFormData({ ...formData, ciudad: event.target.value })
            }
          >
            <option value="" selected>
              {provinciaSeleccionada !== "" ? "Selecciona una ciudad" : ""}
            </option>
            {ciudades.map((ciudad, index) => {
              return (
                <option key={index} value={ciudad.nombre}>
                  {ciudad.nombre}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Group controlId="formBasicNeighborhood">
            <Form.Label>Barrio</Form.Label>
            <Form.Control
              type="text"
              placeholder="Barrio"
              name="barrio"
              value={formData.barrio}
              onChange={(event) =>
                setFormData({ ...formData, barrio: event.target.value })
              }
            />
          </Form.Group>
          <Form.Control.Feedback type="invalid">
            Requerido
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Form.Group className="mb-3" controlId="formBasicNumberTelephone">
        <Form.Label>Teléfono</Form.Label>
        <Form.Control
          type="text"
          placeholder="Teléfono"
          required
          name="telefono"
          value={formData.telefono}
          onChange={(event) =>
            setFormData({ ...formData, telefono: event.target.value })
          }
        />
        <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

export default Step2;
