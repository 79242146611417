import { useForm } from "react-hook-form";
import "./css/mediosEnvios.css"

export default function MediosEnvios() {

    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
    });
    const enviarInfo = (values) => {
        console.log(values);
    }

    return (
        <div className="medios-envio-container">
            <div className='left-container'>
                <h1>Motomensajería Correo Argentino Andreani</h1>
            </div>
            <div className='right-container'>
                <div className="title-container">
                    <p className="section-area">CARRITO DE COMPRAS</p>
                    <h2>Medios de entrega</h2>
                </div>
                <form className="right-form" onSubmit={handleSubmit(enviarInfo)}>
                    <div className="form-header">
                        <div className="form-header-left">
                            <p>icon</p>
                            <div className="transporte-title-container">
                                <h3>MOTOMENSAJERÍA</h3>
                                <p>La entrega se concreta entre el jueves 21/07 y el lunes 25/07</p>
                            </div>
                        </div>
                        <div className="form-header-right">
                            <div className="checkbox">
                                <strong>$600ARS</strong>
                                <label class="option-checkbox-container">
                                    <input type="checkbox"  {...register("pago")} />
                                    <div class="checkmark-option"></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-body">
                        <input className="input-text" type="text" placeholder="Dirección"
                            {...register("Dirección")}
                        />
                        <input className="input-text" type="text" placeholder="Altura"
                            {...register("Altura")}
                        />
                        <input className="input-text" type="text" placeholder="Departamento (Opcional)"
                            {...register("Departamento")}
                        />
                        <input className="input-text" type="text" placeholder="Barrio"
                            {...register("Barrio")}
                        />
                        <input className="input-text" type="text" placeholder="Ciudad"
                            {...register("Ciudad")}
                        />
                        <input className="input-text" type="text" placeholder="Código postal"
                            {...register("CódigoPostal")}
                        />
                        <input className="input-text" type="text" placeholder="Provincia"
                            {...register("Provincia")}
                        />
                        <p>Disponibilidad horaria</p>
                        <div className="selector-container">
                            <select name="desde" id="desde"  {...register("desde")}>
                                <option value="desde">desde</option>
                            </select>
                            <select name="hasta" id="hasta"  {...register("hasta")}>
                                <option value="hasta">hasta</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-footer">
                        <div className="input-checkox-container">
                            <div class="checkbox-wrapper">
                                <input id="_checkbox-26" type="checkbox"
                                    {...register("guardarDomicilio")}
                                />
                                <label for="_checkbox-26">
                                    <div class="tick_mark"></div>
                                </label>
                            </div>
                            <label>Guardar domicilio para futuras compras</label>
                        </div>
                        <input type="submit" value="REALIZAR EL PEDIDO" className="submit-button" />
                    </div>
                </form>
            </div>
        </div>
    )
}
