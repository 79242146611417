import { useContext, useEffect } from "react";
import "../../css/profesional.css"
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";

export default function Profesional() {
  const profesionales = [
    {
      name: "gonzalo",
      apellido: "mina",
      dni: 40900150,
      provincia:"buenos aires"
    },
  ];
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  const breadcrumb = [
    { caption: "Profesionales", href: "#" },
  ]
  useEffect(() => {
    setBreadcrumb(breadcrumb);
    setHeaderTitle('Profesionales');
  }, [])
  
  return (
    <ul className="profesional-list">
      {profesionales.map((profesional) => {
        return (
          <li className="profesional-item">
            <p> {profesional.name} </p>
            <p> {profesional.apellido} </p>
            <p> {profesional.dni} </p>
            <p> {profesional.provincia} </p>
            <div className="button-container">
                <button className="button">Aceptar</button>
                <button className="button">Rechazar</button>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
