import React from "react";
import { Table, Row, Col, Button } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import {TiDelete} from "react-icons/ti";
import "../../../css/promotions.css";

const SectorsTable = (props) => {
  const { sectors, onClick } = props;
  console.log(sectors.results);
  // sectors = sectors.results.map((sector)=>{
  //   title: sector.name!=null ? sector.name : sector.title
  // })
  return (
    <div className="accessories-table-container">
      <Table className="table-container">
        <thead>
          <tr>
            <th>TÍTULO</th>
          </tr>
        </thead>
        <tbody>
          {
          sectors?.length !== 0 &&
          sectors?.results != null ?
           (
            sectors?.results.map((element) => (
              <tr key={Math.random()}>
                <td>{element.name}</td>
                <td className="container-btn-actions">
                  <Row className="justify-content-center">
                    <Col xs={4} className="text-center">
                      <Button
                        onClick={() => onClick(element, "Editar")}
                        className="btn-action btn-editar"
                      >
                        <BsPencilSquare size={22} className="icon icon-edit" />
                        <p>Editar</p>
                      </Button>
                    </Col>
                    <Col xs={4} className="text-center">
                      <Button
                        onClick={() => onClick(element, "Eliminar")}
                        className="btn-action btn-editar"
                      >
                        <TiDelete size={26} className="icon icon-delete" />
                        <p>Eliminar</p>
                      </Button>
                    </Col>
                  </Row>
                </td>
              </tr>
            ))
          ) 
          : (
            <tr className="text-center">
              <td colSpan={6}>No hay sectores</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default SectorsTable;
