import { SimpleControlText } from "../../../helpers/SimpleControlText";

export const Prices = ({ entityErrors, entity, onControlChange }) => {
  const publicPrice = entity.promotionType? "publicPrice": "purpose.publicPrice" ;
  const professionalPrice = entity.promotionType? "professionalPrice": "purpose.professionalPrice" ;
  return (
    <div className="mb-3 w-100 d-flex flex-row">
      <div className={"col-sm-4 col-form-label fs-6 fst-italic "}>Precio</div>
      <div className="col-sm-8 row d-flex flex-row justify-content-between">
        <div className="col-sm-6 pe-2 ps-0">
          <SimpleControlText
            name={professionalPrice}
            errors={entityErrors}
            data={entity}
            type="number"
            onChange={onControlChange}
            placeholder={"Profesional"}
            disabled={ entity.variantes === 'Tiene variantes'}
          />
        </div>
        <div className="col-sm-6 px-2">
          <SimpleControlText
            name={publicPrice}
            errors={entityErrors}
            data={entity}
            type="number"
            onChange={onControlChange}
            placeholder={"Publico"}
            disabled={entity.uso ? (entity.uso !== "Público" || entity.variantes === 'Tiene variantes') : false || entity.uso}
          />
        </div>
      </div>
    </div>
  );
};
