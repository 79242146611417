import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  LOGIN,
  PUBLIC_REGISTER,
  PROFESSIONAL_REGISTER,
  HOME,
  ABM_PRODUCTS,
  ABM_PROMOTIONS,
  ABM_SHOPS,
  ABM_ACADEMIC_OFFER,
  ABM_PROFESSIONALS,
  ABM_SERVICES,
  ABM_CATEGORIES,
  ABM_CONFIGURATION,
  LOGOUT,
  ABM_ACCESSORIES,
  INIT,
  HOME_LANDING,
  PRODUCTS_PAGE,
  PRODUCT_PAGE,
  PROMOS,
  CHECKOUT,
  ESTADO_PEDIDO,
  MEDIOS_ENTREGA,
  MEDIOS_PAGO,
  REGISTRO,
  TRAKER,
  DATOS_USUARIO,
  DATOS_PEDIDO,
  ABM_ORDER,
  ABM_SECTORS
} from "./config/router/routes.js";
import Login from "./views/Login";
import PublicRegister from "./views/PublicRegister";
import ProfessionalRegister from "./views/ProfessionalRegister";
import Home from "./views/Home";
import HomePage from "./components/home-page/HomePage"
import AbmStores from "./views/abm/Stores";
import AbmProducts from "./views/abm/Products";
import AbmPromotions from "./views/abm/Promotions";
import AbmCategories from "./views/abm/Categories";
import AbmAccessories from "./views/abm/Accessories";
import GenericOptionDisable from "./views/abm/GenericOptionDisable";
import Init from "./views/Init";
import "./App.css";
import { PrivateLayout, PublicLayout } from "./components/layout";
import React from "react";
import { useAppContext } from "./App";
import { Logout } from "./views/Logout";
import Configuration from "./views/abm/Configuration";
import PaginaProductosViews from "./components/home-page/components/views/paginaProductosViews";
import PaginaProductoView from "./components/home-page/components/views/paginaProductoView";
import PaginaPromosView from "./components/home-page/components/views/paginaPromosView";
import PaginaCheckoutView from "./components/home-page/components/views/PaginaCheckoutView";
import EstadoPedido from "./components/home-page/components/EstadoPedido";
import MediosEnvios from "./components/home-page/components/MediosEnvios";
import MediosPago from "./components/home-page/components/MediosPago";
import TrakerPageView from "./components/home-page/components/views/TrakerPageView.jsx"
import PaginaRegistroView from "./components/home-page/components/views/PaginaRegistroView.jsx";
import PaginaPedidoView from "./components/home-page/components/views/PaginaPedidoView.jsx";
import DatosUsuario from "./components/home-page/components/DatosUsuario.jsx";
import PaginaDatosView from "./components/home-page/components/views/paginaDatosView.jsx";
import DatosPedido from "./components/home-page/components/DatosPedido.jsx";
import PaginaDatosPedidoView from "./components/home-page/components/views/PaginaDatosPedidoView.jsx"
import Ordenes from "./views/abm/Ordenes.js";
import Sectores from "./views/abm/Sectors.js";
import Profesional from "./views/abm/Profesional.jsx";

export const AppRoutes = () => {
  const appContext = useAppContext();
  console.log(appContext)

  const divStyle = {
    display: "flex",
    margin: "auto",
  };

  return (
    <BrowserRouter>
      <Routes>
        {
          appContext.isAuth && (appContext.role === "ADMIN" || appContext.role === "STORE_ADMIN") &&
          <Route path="" element={<PrivateLayout sectorId={appContext.sectorId} />}>
            <Route path="/" element={<Init />} />
            <Route path={INIT} element={<Init />} />
            <Route path={HOME} element={<Home />} />
            <Route path={ABM_SHOPS} element={<AbmStores />} />
            <Route path={ABM_ACADEMIC_OFFER} element={<GenericOptionDisable text={'Oferta Académica'} />} />
            <Route path={ABM_PROFESSIONALS} element={<Profesional/>} />
            <Route path={ABM_SERVICES} element={<GenericOptionDisable text={'Servicios'} />} />
            <Route path={ABM_PRODUCTS} element={<AbmProducts />} />
            <Route path={ABM_PROMOTIONS} element={<AbmPromotions />} />
            <Route path={ABM_ORDER} element={<Ordenes></Ordenes>} />
            <Route path={ABM_CATEGORIES} element={<AbmCategories />} />
            <Route path={LOGOUT} element={<Logout />} />
            <Route path={ABM_ACCESSORIES} element={<AbmAccessories />} />
            <Route path={ABM_SECTORS} element={<Sectores />} />
            <Route path={ABM_CONFIGURATION} element={<Configuration />} />
            {/* <Route path="*" element={<Navigate to={"/"} replace={true} />} /> */}
          </Route>
        }
        {
          !appContext.isAuth &&
          <Route path="" element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
            <Route path={PROFESSIONAL_REGISTER} element={<ProfessionalRegister />} />
            <Route path={PUBLIC_REGISTER} element={<PublicRegister />} />
            <Route path={LOGIN} element={<Login />} />
            <Route path={HOME} element={<Home />} />
            {/* <Route path="*" element={<Navigate to={HOME} replace={true} />} /> */}
            <Route path={HOME_LANDING} element={<HomePage />} />
          </Route>
        }
        <Route >
          <Route path={HOME_LANDING} element={<HomePage />}> </Route>
          <Route path={PRODUCT_PAGE} element={<PaginaProductoView></PaginaProductoView>} />
          <Route path={PROMOS} element={<PaginaPromosView></PaginaPromosView>} />
          <Route path={PRODUCTS_PAGE} element={<PaginaProductosViews />} />
          <Route path={`${PRODUCTS_PAGE}/:ids`} element={<PaginaProductosViews />} />
          <Route path={CHECKOUT} element={<PaginaCheckoutView />} />
          <Route path={ESTADO_PEDIDO} element={<PaginaPedidoView></PaginaPedidoView>} />
          <Route path={MEDIOS_ENTREGA} element={<MediosEnvios></MediosEnvios>} />
          <Route path={MEDIOS_PAGO} element={<MediosPago></MediosPago>} />
          <Route path={REGISTRO} element={<PaginaRegistroView></PaginaRegistroView>} />
          <Route path={TRAKER} element={<TrakerPageView></TrakerPageView>} />
          <Route path={DATOS_USUARIO} element={<PaginaDatosView></PaginaDatosView>} />
          <Route path={DATOS_PEDIDO} element={<PaginaDatosPedidoView></PaginaDatosPedidoView>} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}