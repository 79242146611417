import { useFilters } from "./hooks/useFilters"

export default function FiltersMaxMin() {

    const { filters, setFilters } = useFilters()

    const handleChangeRandomPrice = () => {
        setFilters({ ...filters, priceOrder: 0 })

    }
    const handleChangeMinPrice = () => {
        setFilters({ ...filters, priceOrder: 1 })
    }
    const handleChangeMaxPrice = () => {
        setFilters({ ...filters, priceOrder: 2 })
    }
    return (
        <aside className="filtros-container">
            <div className="filtro-precio">
                <h3 className="filtro-titulo">ORDENAR POR</h3>
                <ul>
                    <li > <input type="text" onClick={handleChangeRandomPrice} value="Aleatorio" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMinPrice} value="Precio - Menor a mayors" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMaxPrice} value="Precio - Mayor a menor" readOnly /></li>
                </ul>
            </div>
        </aside>
    )
}
