import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import PaginaPromos from '../PaginaPromos'
import usePromotions from '../hooks/usePromotions'

export default function PaginaPromosView() {
    const { promotions } = usePromotions()
    console.log(promotions);

    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <PaginaPromos productos={promotions} ></PaginaPromos>
            <Footer></Footer>
        </>
    )
}
