import * as React from "react"
const SvgBack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={170.667}
    height={170.667}
    viewBox="0 0 128 128"
    {...props}
  >
    <path d="M59.7 30.8C35.8 54.7 29 62.1 29 64c0 3.4 60.3 64 63.8 64 3 0 6.2-3.4 6.2-6.7 0-2.2-5-7.7-27.2-30L44.5 64l27.3-27.3C94 14.4 99 8.9 99 6.7 99 3.4 95.8 0 92.7 0c-1.6 0-10.8 8.6-33 30.8z" />
  </svg>
)
export default SvgBack
