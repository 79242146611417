import { AddIcon } from "../../../icons/AddIcon";
import { TrashIcon } from "../../../icons/TrashIcon";

export const ListControl = ({ addNew, removeLast, list }) => {
  return (
    <div className="mb-3 row mb-3 row w-100 d-flex flex-row">
      <div className="col-sm-4 col-form-label fs-6"></div>
      <div className={`col-sm-8 d-flex flex-row p-0 align-items-center`}>
        <div className="flex me-4 cursor-pointer" onClick={addNew}>
          <AddIcon />
          Anadir
        </div>
        {list.length > 1 && (
          <div className="flex cursor-pointer" onClick={removeLast}>
            <TrashIcon />
            Borrar ultimo
          </div>
        )}
      </div>
    </div>
  );
};
