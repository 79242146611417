// SearchBar.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRODUCTS_PAGE } from '../../../config/router/routes';
import { SearchIcon } from '../assets/icons';
import "./css/searchBar.css"

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (query.trim()) {
            navigate(`/${PRODUCTS_PAGE}?search=${encodeURIComponent(query)}`);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="buscador-container">
            <input
                className="buscador"
                placeholder='buscar'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyPress}
            ></input>
            <div className="borde"> <SearchIcon fill={"var(--icon-color)"} width={16} height={16}></SearchIcon></div>
            {/* <div className="search-container">
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Buscar productos..."
            />
        </div> */}
        </div>

    );
};

export default SearchBar;
