import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const EditCategoryModal = (props) => {
  const {
    type,
    categories,
    sectors,
    categoryName,
    parentCategoryId,
    categoryIsPublic,
    categorySector,
    show,
    close,
    edit,
  } = props;

  const [newCategory, setNewCategory] = useState(categoryName);

  const [selectValue, setSelectValue] = useState(parentCategoryId);

  const [newIsPublic, setNewIsPublic] = useState(false);

  const [selectSectorValue, setSelectSectorValue] = useState(null);

  const handleNameChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleSelectSectorChange = (event) => {
    setSelectSectorValue(event.target.value !== "" ? event.target.value : null);
  };
  
  const handleIsPublicChange = (event) => {
    setNewIsPublic(!newIsPublic);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.value !== "")
      edit(
        newCategory,
        selectValue === 0 ? null : selectValue,
        newIsPublic,
        selectSectorValue
      );
    setNewCategory("");
    setSelectValue(0);
    setSelectSectorValue(null);
    setNewIsPublic(false);
    close();
  };

  useEffect(() => {
    setNewCategory(categoryName);
    setSelectValue(parentCategoryId);
    setSelectSectorValue(categorySector);
    setNewIsPublic(categoryIsPublic);
  }, [categoryName, parentCategoryId, categoryIsPublic, categorySector]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "sub" ? "Editar subcategoría" : "Editar categoría"}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newCategory}
              onChange={handleNameChange}
              required
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="sectorId">
            
          <Form.Select
                id="selectSector"
                value={selectSectorValue || ""}
                onChange={handleSelectSectorChange}
              >
                <option value="">Seleccioná el sector</option>
                {sectors.map((sector) => {
                  return (
                    <option key={sector.value} value={sector.value}>
                      {sector.value}
                    </option>
                  );
                })}
              </Form.Select>
          </Form.Group>

          <Form.Group className="mt-3" controlId="isPublic">
            <Form.Check
            label="Es Publico"
            value={newIsPublic}
            onChange={handleIsPublicChange}
          />
          </Form.Group>

          {type === "sub" && (
            <Form.Group className="my-3">
              <Form.Label htmlFor="selectCategories">
                Categoría asociada
              </Form.Label>

              <Form.Select
                id="selectCategories"
                value={selectValue}
                onChange={handleSelectChange}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            disabled={!newCategory }
          >
            Actualizar
          </Button>
          <Button variant="secondary" onClick={close}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
