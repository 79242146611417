import React, { useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
const objectPath = require("object-path");

export const ControlTextarea = ({
  name,
  label,
  placeholder,
  disabled = false,
  onChange,
  data,
  start,
  end,
  errors = [],
  ...options
}) => {
  const target = useRef();
  const value = data ? objectPath(data).get(name, "") : "";
  const error = errors.find((e) => e.path === name);

  return (
    <>
      <div
        ref={target}
        className="mb-3 row mb-3 row w-100 d-flex flex-row"
        style={{ minHeight: "35px" }}
      >
        <label
          htmlFor={name}
          className="col-sm-4 col-form-label fs-6 fst-italic"
        >
          {label}
        </label>
        <div
          className={`col-sm-8 d-flex flex-row p-0 ${
            disabled ? "disable-input" : ""
          }`}
          style={{ backgroundColor: "white" }}
        >
          {start}
          <textarea
            id={name}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            rows={4}
            {...options}
            className="flex-grow-1 px-2"
            style={{ border: "0px solid white" }}
            disabled={disabled}
          />
          {end}
        </div>
      </div>
      <Overlay target={target.current} show={error} placement="right">
        {(props) => (
          <Tooltip
            id={`tooltip-${name}`}
            {...{ ...props, style: { ...(props.style || {}), opacity: 0.8 } }}
          >
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
