import { useState, useEffect } from 'react';
import "./css/progressBarEnvio.css"

const ProgressBar = ({ percentage }) => {
    return (
        <div className="progress-bar-envio">
            <div className="progress" style={{ width: `${percentage}%` }}></div>
        </div>
    );
};

export const ProgressBarApp = ({ ciclo }) => {
    const [progress, setProgress] = useState(0);

    // Simular el progreso del envío (esto puede variar según tus necesidades)
    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 20) {
                setProgress(progress + 20);
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [progress]);

    return (
        <div className='progess-container'>
            <h1>Estado de Envío: {ciclo}</h1>
            <ProgressBar percentage={progress} />

        </div>
    );
};


