
const SvgNext = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props}
        height={props}
        viewBox="0 0 512 512"
        {...props}
    >
        <path d="M134 .9c-11.8 3.6-20 15.6-18.7 27.2.3 2.7 1.4 6.6 2.3 8.6 1.1 2.5 37.7 39.8 109.3 111.6L334.5 256 226.9 363.8c-71.6 71.7-108.2 109-109.3 111.5-6.1 13.5.4 29.6 14 34.7 6.6 2.4 11.4 2.5 18 .4 4.5-1.5 14.6-11.3 124.2-120.8C383.3 280.3 393.2 270.1 395 265.2c1.1-2.9 2-7 2-9.2 0-2.2-.9-6.3-2-9.2-1.8-4.9-11.7-15.1-121.2-124.4C164.2 12.9 154.1 3.1 149.6 1.6c-5.2-1.7-11.4-2-15.6-.7z" />
    </svg>
)
export default SvgNext
