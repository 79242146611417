
import SvgNext from "../assets/Next";
import "./css/menuDesplegable2.css"

export default function MenuDesplegable({ categorie, parentCategoryUnFiltered }) {
    const parentCategoryName = parentCategoryUnFiltered?.name

    const childCategories = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory === null
            && category?.parentCategory[0]?.name === parentCategoryName
    );

    // categorie?.categories?.map((category) => {
    //     // console.log(category?.parentCategory );
    //     if (category.parentCategory !== null){
    //         if(category?.parentCategory[0]?.parentCategory !== null)
    //             console.log(category?.parentCategory[0]?.parentCategory[0]?.name); 
    //     }
    //     return "gg"
    // })
    const Categories3 = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0]?.name === parentCategoryName
    );
    const Categories4 = categorie?.categories?.filter(
        (category) => category.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0].parentCategory !== null
            && category?.parentCategory[0]?.parentCategory[0]?.parentCategory[0].name === parentCategoryName
    );
    
    return (
        <div className="menu-container">
            <div className="triangulo-equilatero"></div>
            <ul className="menu-vertical">
                <h2 className="menu-vertical-title">#IDRAETDERMOPURITY</h2>

                {childCategories?.map((parentCat, index) => {
                    return (
                        <li className="seccion" key={index}>
                            <a className="category-section"> {parentCat.name} <SvgNext width={14} fill={"var(--white-color)"}></SvgNext></a>
                            <ul className="menu-final">
                                <ul>
                                    <h2 className="parent-category"> {parentCat.name} </h2>
                                    <div className="grid-container">
                                        {Categories3?.map((child, childIndex) => {
                                            return (
                                                <div key={childIndex}>
                                                    <h3 className="seccion">
                                                        {/* {child.parentCategory.id === parentCat.id ? child.name : ""} */}
                                                        {child.name}
                                                    </h3>
                                                    {Categories4?.map((child4, child4Index) => {
                                                        return (
                                                            //child4.parentCategory.id === child.id ? (
                                                                <ul key={child4Index}>
                                                                    <li className="seccion">{child4.name}</li>
                                                                </ul>
                                                            //) : null
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </ul>
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
