/* eslint-disable react/prop-types */
import { useState } from "react";
import estilos from "./css/slider.css"
import SvgNext from "../assets/Next";
import SvgBack from "../assets/Back";
import imagenes1 from "../assets/imagenes-01.svg"
import { ArrowIcon } from "../assets/icons";
import { REGISTRO } from "../../../config/router/routes";

export default function Slider({ imagenes }) {
    const [imagenActual, setImagenActual] = useState(0);
    const cantidad = imagenes[0]?.image?.length;
    // console.log(imagenes);
    // Return prematuro para evitar errores
    // if (!Array.isArray(imagenes) || cantidad === 0) return;

    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 0 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 0 ? cantidad - 1 : imagenActual - 1);
    };

    return (
        <div className="slider-container-1">
            <button onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
            <div className="contenedor-releative-1">
                {/* <img src={imagenes1} className="imagen" alt="imagen principal" />
                <div className="title-container">
                    <h2 className="slider-1-title">todo lo que necesitas</h2>
                    <p className="slider-1-p">para tu gabinete</p>
                </div>
    <button className="blue-button">Registrate y Compra <ArrowIcon width={30} fill={"var(--primary-color)"}></ArrowIcon> </button>*/}
                {imagenes[0]?.image?.map((imagen, index) => {
                    return (
                        <div key={index}
                            className={
                                imagenActual === index
                                    ? `${estilos.slide} ${estilos.active}`
                                    : estilos.slide
                            }>
                            {/* <img src={imagenes1} alt="imagen" /> */}
                            {imagenActual === index && (
                                <div className="contenedor-releative-1">
                                    <img src={`data:image/png;base64,${imagen?.base64}`} alt="imagen" />
                                    <div className="title-container">
                                        <h2 className="slider-1-title">todo lo que necesitas</h2>
                                        <p className="slider-1-p">para tu gabinete</p>
                                    </div>
                                    <a href={REGISTRO} className="blue-button">Registrate y Compra <ArrowIcon width={30} fill={"var(--primary-color)"}></ArrowIcon> </a>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <button onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
        </div>
    );

}
