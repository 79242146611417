import { createContext, useState } from "react";

export const LogInContext = createContext()

export function LogInProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [Auth, setAuth] = useState(true)

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const handleAuth = () => {
        setAuth(!Auth)
    }

    return (
        <LogInContext.Provider value={{
            isOpen, Auth, openModal, closeModal, handleAuth
        }
        }>
            {children}
        </LogInContext.Provider>
    )
} 
