import React from "react";
import { useState, useEffect } from "react";
import AccessoriesTable from "./AccessoriesTable";
import ModalEdit from "./ModalEdit";
import ModalDelete from "../../modals/ModalDelete";
import { getAll, update, remove } from "../../../services/abm";

function ManageAccessories(props){

  const { accessories } = props;
  const [accesorioSeleccionada, setAccesorioSeleccionada] = useState({
      id: 0,
      title: "",
  });
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const seleccionarAccesorio = (element, caso) => {
    setAccesorioSeleccionada(element);
    if (caso === "Editar") setShowModalEdit(true);
    else setShowModalDelete(true);
  };
  const handleCloseModalEdit = () => setShowModalEdit(false);
  const handleCloseModalDelete = () => setShowModalDelete(false);
  const edit = (body) =>  update(body, accesorioSeleccionada.id, "accessories", "PUT");
  const removeAccessory = () => remove(accesorioSeleccionada.id, "accessories");


 
  return(
      <div>
          <AccessoriesTable
              accessories={accessories}
              onClick={seleccionarAccesorio}
          />

          <ModalEdit
              accesorioSeleccionada={accesorioSeleccionada}
              setAccesorioSeleccionada={setAccesorioSeleccionada}
              show={showModalEdit}
              close={handleCloseModalEdit}
              edit={edit}
          />

          <ModalDelete
              title="accessorio"
              show={showModalDelete}
              close={handleCloseModalDelete}
              remove={removeAccessory}
          >
              {`¿Estás seguro/a que querés eliminar la promoción ${accesorioSeleccionada.title}?`}
          </ModalDelete>
      </div>
  )
}

export default ManageAccessories;