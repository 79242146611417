import "./css/checkout.css"
import { AsideCart } from "./Cart"
import { useForm } from "react-hook-form"
import iconAndreani from "../assets/icon checkout/Sin título-15-03.svg"
import iconMoto from "../assets/icon checkout/Sin título-15-05.svg"
import iconMercadoPago from "../assets/icon checkout/icons8-mercado-pago-240.svg"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from "./hooks/useCart"
import iconEfectivo from "../assets/icon checkout/Sin título-15-08.svg"

const EntregaOption = ({ register, icon, title, date, price, handleCheckboxChange, opcion, errors, defaultChecked }) => (
    <>
        <div className='contenedor-option'>
            <div className="left-container">
                <img className="icon-checkout" src={icon} alt={title}></img>
                <div className="option-title-container">
                    <h3> {title} </h3>
                    <small> {date} </small>
                </div>
            </div>
            <div className="checkbox">
                <strong>${price}ARS</strong>
                <label className="option-checkbox-container">
                    <input
                        type="checkbox"
                        value={opcion}
                        defaultChecked={defaultChecked} // Establece el valor predeterminado aquí
                        {...register('medioEnvio', { required: true })}
                        onChange={() => handleCheckboxChange(opcion, 'medioEnvio')}
                    />
                    {/* {errors.selectedCheckbox && <p className="error-message">Selecciona al menos una opción</p>} */}
                    <div className="checkmark-option"></div>
                </label>
            </div>
        </div>
    </>
);

const PagoOption = ({ icon, title, register, errors, handleCheckboxChange, opcion }) => {
    return (
        <div className='contenedor-option'>
            <div className="left-container">
                {title === "Mercado pago" ? <img className="icon-checkout-mercado" src={icon} alt={title}></img> : <img className="icon-checkout" src={icon} alt={title}></img>}
                <div className="option-title-container">
                    <h3> {title} </h3>
                </div>
            </div>
            <div className="checkbox-2">
                <label className="option-checkbox-container">
                    <input
                        type="checkbox"
                        value={opcion}
                        {...register('medioPago', { required: true })}
                        onChange={() => handleCheckboxChange(opcion, 'medioPago')}
                    />
                    {errors.medioPago?.type === "required" && <p>el checkbox esta requerido</p>}
                    <div className="checkmark-option">
                        <button style={{ display: "none" }}>
                        </button>
                    </div>

                </label>
            </div>
        </div>
    )
}

export default function PaginaCheckout() {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        mode: 'onBlur',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState([])
    const baseUrl = process.env.REACT_APP_URL_BACKEND;
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const { cart } = useCart()
    const navigate = useNavigate();
    // const redirect = (url) => { navigateCheckout(url) }
    const tipoDeFactura = [
        { codigo: "CF", Descripción: "CONSUMIDOR FINAL" },
        { codigo: "EX", Descripción: "EXENTO" },
        { codigo: "RI", Descripción: "RESPONSABLE INSCRIPTO" },
        { codigo: "RS", Descripción: "RESPONSABLE MONOTRIBUTISTA" }]

    const handleCheckboxChange = (selectedValue, selectedCheck) => {
        const currentValuePago = getValues(selectedCheck);
        const currentValueEnvio = getValues(selectedCheck)
        let currentValue
        setValue('medioEnvio', "opcion 1")
        console.log(selectedValue);

        if (selectedCheck === "medioPago") {
            currentValue = currentValuePago
            if (currentValue === selectedValue) {
                setValue('medioPago', null);
            }
            else {
                setValue('medioPago', selectedValue);
            }
        }
        else if (selectedCheck === "medioEnvio") {
            currentValue = currentValueEnvio
            if (currentValue === selectedValue) {
                setValue('medioEnvio', null);
            }
            else {
                setValue('medioEnvio', selectedValue);
            }
        }
    };

    const enviarInfo = (values) => {
        console.log(values);

        const configuracion = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cart.map(product => {
                return {
                    id: product.id,
                    quantity: product.quantity
                }
            }))
        };
        // if (trasfer === true) {
        //     const url = `${baseUrl}/mercadopago/transfer`;
        // }
        const url = `${baseUrl}/mercadopago`;
        // console.log(configuracion);

        fetch(url, configuracion)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`La petición falló con código de estado ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // Manejar los datos de la respuesta
                console.log('Respuesta del servidor:', data);
                const initPoint = data.initPoint;
                console.log(initPoint);
                window.location.href = initPoint
                // navigate(`/${initPoint}`, { replace: true })
            })
            .catch(error => {
                console.error('Error al realizar la petición:', error);
            });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}/users/${email}`, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDefaultValue(data)
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token]);

    console.log(defaultValue);
    // console.log(defaultValue?.firstName);
    useEffect(() => {
        console.log(defaultValue);
    }, [defaultValue])

    if (isLoading) {
        // Mientras se cargan los datos, podrías mostrar un indicador de carga o mensaje
        return <div>Cargando...</div>;
    }

    return (
        <div className="checkout-container">
            <h1 className="title-checkout">Carrito De Compras</h1>
            <div className="checkout">

                <div className='columna-izquierda'>
                    <form className="form-checkout" onSubmit={handleSubmit(enviarInfo)}>
                        <div className='medios-entrega-container'>
                            <h2>MEDIOS DE ENTREGA</h2>
                            <EntregaOption
                                errors={errors}
                                register={register}
                                handleCheckboxChange={handleCheckboxChange}
                                icon={iconMoto}
                                title={"MOTOMENSAJERÍA"}
                                date={"La entrega se concreta entre el jueves 21/07 y el lunes 25/07"}
                                price={600}
                                opcion={"opcion 1"}
                                defaultChecked="true"
                            />
                            <EntregaOption
                                errors={errors}
                                register={register}
                                handleCheckboxChange={handleCheckboxChange}
                                icon={iconAndreani}
                                title={"MOTOMENSAJERÍA"}
                                date={"La entrega se concreta entre el jueves 21/07 y el lunes 25/07"}
                                price={600}
                                opcion={"opcion 3"}
                            />
                        </div>

                        <div className="medios-entrega-container">
                            <h2>MEDIOS DE PAGO</h2>
                            <PagoOption
                                icon={iconEfectivo}
                                title={"Tarjeta"}
                                register={register}
                                errors={errors}
                                handleCheckboxChange={handleCheckboxChange}
                                opcion={"tarjeta"}
                            />
                            <PagoOption
                                icon={iconMercadoPago}
                                title={"Mercado pago"}
                                register={register}
                                errors={errors}
                                handleCheckboxChange={handleCheckboxChange}
                                opcion={"mercadoPago"}
                            />

                        </div>

                        <h2 className="title-form">DATOS DE FACTURACIÓN</h2>
                        {defaultValue !== null &&
                            <>
                                <div className="form-checkout-1">
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Nombre"
                                        defaultValue={defaultValue?.firstName}
                                        {...register("nombre")}
                                    />
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Apellido"
                                        defaultValue={`${defaultValue?.lastName}`}
                                        {...register("Apellido")}
                                    />
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Email"
                                        defaultValue={defaultValue?.email}
                                        {...register("Email", {
                                            pattern: /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                    />
                                    {errors.Email?.type === "pattern" && <p className="error">introduce un email valido</p>}
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Teléfono"
                                        defaultValue={defaultValue?.mobilePhoneNumber}
                                        {...register("Teléfono")}
                                    />
                                    <input className="input-form"
                                        type="text"
                                        placeholder="DNI"
                                        defaultValue={defaultValue?.identificationNumber}
                                        {...register("DNI")}
                                    />
                                    <input className="input-form"
                                        type="text"
                                        placeholder="CUIL"
                                        {...register("CUIL")}
                                    />
                                    <select name="hasta" id="hasta" placeholder="tipoDeFactura">
                                        <option value="" disabled>tipo de Factura</option>
                                        {tipoDeFactura.map(factura => (
                                            <option value={factura.codigo}>{factura.Descripción}</option>
                                        ))}
                                    </select>
                                    <div className="input-checkox-container">
                                        <div className="checkbox-wrapper">
                                            <input id="_checkbox-26" type="checkbox" {...register("recibidor")} />
                                            <label htmlFor="_checkbox-26">
                                                <div className="tick_mark"></div>
                                            </label>
                                        </div>
                                        <label>Otra persona recibirá el pedido</label>
                                    </div>

                                </div>

                                <h2 className="title-form">PERSONA QUE RECIBIRÁ EL PEDIDO</h2>
                                <div className="form-checkout-2">
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Nombre"
                                        {...register("nombre")}
                                    />
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Apellido"
                                        {...register("Apellido")}
                                    />
                                </div>

                                <h2 className="title-form">CÓDIGO DE TU PROFESIONAL <span> (Opcional) </span></h2>
                                <div className="form-checkout-2">
                                    <input className="input-form"
                                        type="text"
                                        placeholder="Código"
                                        {...register("Código")}
                                    />
                                    <label>
                                        Ingresá el código de tu profesional.
                                    </label>
                                </div>

                                <h2 className="title-form">NOTA DE PEDIDO</h2>
                                <div className="form-text-area">
                                    <textarea className="text-area"
                                        {...register("nota")}
                                    ></textarea>
                                    <input
                                        type="submit"
                                        value="REALIZAR EL PEDIDO"
                                        className="submit-button"
                                    />
                                </div>
                            </>

                        }
                    </form>
                </div>

                <div className='columna-derecha'>
                    <h2>PRODUCTOS</h2>
                    <AsideCart></AsideCart>
                </div>
            </div>
        </div>
    )
}
