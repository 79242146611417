/* eslint-disable react/prop-types */
import "./css/registrate.css"
import imagenes10 from "../assets/imagenes-10.svg"
import Header from "./Header"
import { UseAuth } from "./hooks/UseAuth"

export default function Registrate({ imagenes }) {
    const { isOpen, Auth, openModal, closeModal } = UseAuth()

    return (
        <div className="registrate-container">
            <div className="text-container">
                <p className="section-area">registrate</p>
                <h3 className="section-title">Unite a nuestra comunidad de profesionales</h3>
                <p>Registrate como profesional de la estética y accedé a numerosos
                    beneficios y descuentos, además de pertenecer a nuestra red
                    de negocio para potenciales clientes.
                </p>
                <a href="/registro" className="button-registrate">REGISTRATE</a>
                <p>¿ya sos usuario? <button onClick={openModal} className="sesion">inicia sesion</button> </p>
            </div>
            <img alt="profesional trabajando" src={imagenes10}></img>
        </div>
    )
}
