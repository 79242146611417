import { ImgContainer } from "./Slider2"
import { SliderNav } from "./SliderNav"
import "../components/css/paginaPromos.css"
import FiltersMaxMin from "./filtersMaxMin"


export default function PaginaPromos({ productos }) {

    const categoryNav = ["Todos", "Ácido Hialurónico", "Ácido Ferrulico", "Vitamina C", "Retinol", "Osmo-Hidratación",
        "Todos", "Ácido Hialurónico", "Ácido Ferrulico", "Vitamina C", "Retinol", "Osmo-Hidratación"]

    return (
        <div className="pagina-productos-container">

            <FiltersMaxMin></FiltersMaxMin>
            <div className="pagina-promos">
                <h2>Promociones</h2>
                <SliderNav navCategory={categoryNav}></SliderNav>
                <div className="productos">
                    {productos?.map((producto, index) => {
                        return (
                            <div key={index}>
                                <ImgContainer imagen={producto.image} description={producto.categorie} titulos={producto.title}></ImgContainer>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
