// import { useContext, useEffect, useState } from "react";
// import "../../css/ordenes.css"
// import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";

// const DatosTabla = ({ datos }) => {
//     return (
//         <table>
//             <thead>
//                 <tr>
//                     <th>ID Orden</th>
//                     <th>Estado</th>
//                     <th>Pagado</th>
//                     <th>Enviado</th>
//                     <th>Es Transferencia</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {datos.map((dato) => (
//                     <tr className="tr-order" key={dato.id}>
//                         <td>{dato.id}</td>
//                         <td>{dato.status}</td>
//                         <td>{dato.paid ? 'Sí' : 'No'}</td>
//                         <td>{dato.shipment ? 'Sí' : 'No'}</td>
//                         <td>{dato.transfer ? 'Sí' : 'No'}</td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// };

// export default function Ordenes() {
//     const [datos, setDatos] = useState([])
//     const baseUrl = process.env.REACT_APP_URL_BACKEND;
//     const url = `${baseUrl}/orders`;
//     const token = localStorage.getItem("token");

//     const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
//     // const [key, setKey] = useState('manage');

//     useEffect(() => {
//         setHeaderTitle("Ordenes");
//         // key === "manage" && getAllPromotionsAndCategories();
//         // if (key === "add") setBreadcrumb(aniadirBreadcrumb);
//         // else setBreadcrumb(administrarBreadcrumb);
//         async function fetchData(url, token) {
//             try {
//                 const response = await fetch(url, {
//                     headers: {
//                         Authorization: "Bearer " + token,
//                     },
//                 });

//                 if (!response.ok) {
//                     throw new Error(`La petición falló con código de estado ${response.status}`);
//                 }

//                 const data = await response.json();
//                 setDatos(data)
//             } catch (error) {
//                 console.error("Error al realizar la petición:", error);
//                 throw error;
//             }
//         }
//         fetchData(url, token)
//     }, []);

//     console.log(datos);

//     return (
//         <div>
//             <h2>Tabla de Datos</h2>
//             <DatosTabla datos={datos} />
//         </div>
//     );
// }

import { useContext, useEffect, useState } from "react";
import "../../css/ordenes.css"
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";

const DatosTabla = ({ datos, handleModify }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>ID Orden</th>
                    <th>Estado</th>
                    <th>Pagado</th>
                    <th>Enviado</th>
                    <th>Es Transferencia</th>
                </tr>
            </thead>
            <tbody>
                {datos.map((dato) => (
                    <tr className="tr-order" key={dato.id}>
                        <td>{dato.id}</td>
                        <td>{dato.status}</td>
                        <td onClick={() => handleModify(dato.id, 'paid', !dato.paid)}>{dato.paid ? 'Sí' : 'No'}</td>
                        <td onClick={() => handleModify(dato.id, 'shipment', !dato.shipment)}>{dato.shipment ? 'Sí' : 'No'}</td>
                        <td onClick={() => handleModify(dato.id, 'transfer', !dato.transfer)}>{dato.transfer ? 'Sí' : 'No'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default function Ordenes() {
    const [datos, setDatos] = useState([])
    const baseUrl = process.env.REACT_APP_URL_BACKEND;
    const url = `${baseUrl}/orders`;
    const token = localStorage.getItem("token");

    const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);

    useEffect(() => {
        setHeaderTitle("Ordenes");
        async function fetchData(url, token) {
            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });

                if (!response.ok) {
                    throw new Error(`La petición falló con código de estado ${response.status}`);
                }

                const data = await response.json();
                setDatos(data)
            } catch (error) {
                console.error("Error al realizar la petición:", error);
                throw error;
            }
        }
        fetchData(url, token)
    }, []);

    const handleModify = async (orderId, field, value) => {
        try {
            const updatedData = datos.map(dato => dato.id === orderId ? { ...dato, [field]: value } : dato);
            setDatos(updatedData);

            // Realizar la solicitud para modificar el campo específico en la orden
            const response = await fetch(`${baseUrl}/orders/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ [field]: value })
            });

            if (!response.ok) {
                throw new Error(`La petición falló con código de estado ${response.status}`);
            }

            // Actualizar el estado con los datos actualizados solo si la solicitud fue exitosa
            setDatos(updatedData);
        } catch (error) {
            console.error("Error al modificar el campo:", error);
            // Manejar errores
        }
    };

    return (
        <div>
            <h2>Tabla de Datos</h2>
            <DatosTabla datos={datos} handleModify={handleModify} />
        </div>
    );
}
