import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingComponent = () => {
    return (  
        <div className="container-loading">
            <Spinner animation="border" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}
 
export default LoadingComponent;