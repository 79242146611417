import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, /* Button, Form */ } from "react-bootstrap";
/* import { GoPlus } from "react-icons/go" */
/* import { create, uploadImage } from "../../../services/abm"; */
/* import moment from "moment"; */
import { ControlSelect, ControlText, ControlTagsSelect } from "../../helpers";
import { ControlFile } from "../../helpers/ControlFile";
import { AbmForm } from "../AbmForm";
import { Prices } from "../stores/personal/Prices";
import { abm } from "../../../services/abm";

const AddPromotions = ({ item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, categories, errorState }) => {
  //const {categories, onClick, promotions, images} = props
  /* const [validated, setValidated] = useState(false);
  const [newImage, setNewImage] = useState([]);
  const [inputKey, setInputKey] = useState(0); */
  const [products, setProducts] = useState([]);
  const [accesories, setAccesories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);


  /* const [promocionSeleccionada, setPromocionSeleccionada] = useState({
    id: 0,
    title: "",
    percentage: 0,
    startDate: "",
    endDate: "",
    type: {
      id: 0,
      name: '',
      parentType: {
        id: 0,
        name: ''
      }
    },
    affectedProducts: "",
    affectedAccesories: "",
    relatedProducts: "",
    image: {
      id: 0,
      type: 0,
    },
    code: ""
  }); */

  /* const [newPromotion, setNewPromotion] = useState({
    title: "",
    percentage: 0,
    startDate: "",
    endDate: "",
    type: 0,
    affectedProducts: [],
    affectedAccesories: [],
    relatedProducts: [],
    image: 0,
    code: "",
  }); */

  /* const handleSubmit = (e) => {
    console.log(newPromotion);
    console.log(newPromotion);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      add({
        title: newPromotion.title,
        percentage: parseInt(newPromotion.percentage),
        startDate: moment(newPromotion.startDate).format('DD-MM-YYYY'),
        endDate: moment(newPromotion.endDate).format('DD-MM-YYYY'),
        type: newPromotion.type,
        affectedProducts: newPromotion.affectedProducts,
        affectedAccesories: newPromotion.affectedAccesories,
        relatedProducts: newPromotion.relatedProducts,
        image: newPromotion.image,
        code: newPromotion.code
      });
    }
    setValidated(true);
  }; */

  /* const add = (newPromotion) => {
    create(newPromotion, "promotions");
  }; */

  const typePromotion = [
    {
      "option": "Porcentaje de descuento sobre monto total",
      "productsPromotions": "Dermopurity - Categoría",
      "product": "Vitamina C - Revitalización y Luminosidad",
      "id": 1
    },
    {
      "option": "Bonificación en producto",
      "productsPromotions": "Fragancias - Categoría",
      "product": "Montblanc Explorer EDP",
      "id": 2
    },
    {
      "option": "Combo de productos",
      "productsPromotions": "Maquillajes - Categoría",
      "product": "Labial Dior",
      "id": 3
    },
    {
      "option": "combo de productos por variantes",
      "productsPromotions": "Skin care - Categoría",
      "product": "Eximia hydramat",
      "id": 4
    }
  ]

  const typePromotions = typePromotion.map((promotion) => ({ value: promotion.id, label: promotion.option }));

  /* const onUploadImages = () => {
    uploadImage(newImage, 3)
      .then(res => res.json())
      .then(res => {
        setNewPromotion({
          ...newPromotion,
          image: parseInt(res.id)
        })
      })
  } */

  /* const resetImages = () => {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  } */

  useEffect(() => {
    abm("products").getAll().then(data => {
      console.log("data", data);
      setProducts(data.products.map(item => ({ value: item.id, label: item.purpose.primaryTitle })));
    });
    abm("accessories").getAll().then(data => {
      console.log("data", data);
      setAccesories(data.map(item => ({ value: item.id, label: item.title })));
    });
  }, []);

  useEffect(() => {
    setSubcategories(categories.filter(item => item.parentCategory != null).map(item => ({ value: item.id, label: item.name })));
  }, [categories]);

  return (<AbmForm onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} title={title} errorState={errorState}>
    {({ onControlChange, entity, entityErrors }) => (

      <>

        <Row>
          <Col className="col-12 col-lg-4 mt-3">
            <ControlSelect
              name="promotionType"
              label="Tipo"
              errors={entityErrors}
              data={entity}
              onChange={onControlChange}
              options={typePromotions}
              placeholder="Seleccionar" />

            {entity.promotionType === '1' && <>
              <ControlText name="bonus" label="Bonificación" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlSelect name="subcategories" label="Subcategoria" errors={entityErrors} data={entity} onChange={onControlChange} options={subcategories} placeholder="Seleccionar" />
              <ControlText name="title" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlFile
                label="Imagen"
                name="promotionFile"
                id="principal"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <ControlText name="code" label="Código" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlTagsSelect name="affectedProducts" label="Productos Afectados" errors={entityErrors} data={entity} onChange={onControlChange} options={products} placeholder="Seleccionar" />
            </>
            }
            {entity.promotionType === '2' && <>
              <ControlTagsSelect name="affectedProducts" label="Productos Afectados" errors={entityErrors} data={entity} onChange={onControlChange} options={products} placeholder="Seleccionar" />
              <ControlTagsSelect name="affectedAccesories" label="Accesorio bonificados" errors={entityErrors} data={entity} onChange={onControlChange} options={accesories} placeholder="Seleccionar" />

              <ControlText name="bonus" label="Bonificación" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlText name="title" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlFile
                label="Imagen"
                id="principal"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <ControlText name="code" label="Código" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
            </>
            }
            {entity.promotionType === '3' && <>
              <ControlTagsSelect name="relatedProducts" label="Productos Afectados" errors={entityErrors} data={entity} onChange={onControlChange} options={products} placeholder="Seleccionar" />
              <Prices
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />
              <ControlText name="title" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlFile
                label="Imagen"
                id="principal"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <ControlText name="code" label="Código" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
            </>
            }
            {entity.promotionType === '4' && <>
              <ControlTagsSelect name="relatedProducts" label="Productos Afectados" errors={entityErrors} data={entity} onChange={onControlChange} options={products} placeholder="Seleccionar" />
              <Prices
                entityErrors={entityErrors}
                entity={entity}
                onControlChange={onControlChange}
              />
              <ControlText name="title" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlFile
                label="Imagen"
                id="principal"
                errors={entityErrors}
                data={entity}
                onChange={onControlChange}
              />
              <ControlText name="code" label="Código" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
            </>
            }
          </Col>


        </Row>

      </>
    )}
  </AbmForm>

    /*
      <div className="add-promotions-main-container promotions-table-container">
          <div className="form-container">
              <Form className="form-add-promotions pt-5" noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="select-container form-group">
                      <span>Tipo</span>
                      <Form.Select 
                          className="input input-select"
                          onChange={(event) => {
                              setNewPromotion({
                                  ...newPromotion,
                                  type: parseInt(event.target.value),
                              })
                          }}>
                          <option value={-1}>
                              Seleccione un tipo de promoción
                          </option>
                          {
                              typePromotion.map((item, i) => (
                                  <option 
                                      key={"promotion"+i} 
                                      
                                      value={item.id}
                                      name="type"
                                      id={i}
                                      >
                                          {item.option}
                                      </option>
                              ))
                          }
                      </Form.Select>
                  </div>
                  
                  <Form.Group className="form-group">
                      <Form.Label>Bonificación</Form.Label>
                      <Form.Control 
                          placeholder="ej: 70%" 
                          className="input"
                          type="number"
                          name="percentage"
                          onChange={(event) => 
                              setNewPromotion({
                                  ...newPromotion,
                                  percentage: event.target.value
                              })
                          }
                          />
                      <ControlText name="name" label="Tienda" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={"Nombre de la tienda"} required />
                  </Form.Group>

                  <div className="select-container form-group">
                      <span>Productos afectados</span>
                      <Form.Select 
                          className="input input-select"
                          onChange={(event) => 
                              setNewPromotion({
                                  ...newPromotion,
                                  affectedProducts: [
                                      ...newPromotion.affectedProducts,
                                  ]
                              })
                          } 
                          >
                          <option value={-1}>Seleccione los productos afectados</option>
                          {
                                  categories.map((item, i) => (
                                      <option 
                                          key={"categoria"+i} 
                                          value={item.name}
                                          name="affectedProducts"
                                          >
                                              {item.name}
                                          </option>
                                  ))
                          }
                      </Form.Select>
                  </div>

                  <div className="select-container form-group">
                      <span>Productos relacionados</span>
                      <Form.Select 
                          className="input input-select"
                          onChange={(event) => 
                              setNewPromotion({
                                  ...newPromotion,
                                  relatedProducts: [
                                      ...newPromotion.relatedProducts,
                                  ]
                              })
                          } 
                          >
                          <option value={-1}>Seleccione los productos relacionados</option>
                          {/* {
                                  typePromotion.map((item, i) => (
                                      <option 
                                          key={"categoria"+i} 
                                          value={item.product}
                                          name="relatedProducts"
                                          >
                                              {item.product}
                                          </option>
                                  ))
                          } }
                      </Form.Select>
                  </div>

                  <Form.Group className="form-group">
                      <Form.Label>Titulo</Form.Label>
                      <Form.Control 
                          placeholder="ej: 70% OFF Montblanc Explorer EDP" 
                          className="input"
                          name="title"
                          onChange={(event) => 
                              setNewPromotion({
                                  ...newPromotion,
                                  title: event.target.value
                              })
                          }
                      />
                  </Form.Group>

                  <Form.Group className="form-group">
                      <Form.Label>Imagen</Form.Label>
                      <div className="input upload-container">
                          <div className="upload-file" onClick={() => onUploadImages()}>
                              <GoPlus size={19} className="plus-icon"/>
                              <span>Subir archivo</span>
                          </div>
                          <div className="file-name-container">
                              <Form.Control
                                  type="file"
                                  name="photos"
                                  required
                                  accept="image/png, image/jpeg, image/jpg"
                                  style={{color: newImage.length ? "#fff" : "#2e2e2e"}}
                                  placeholder="Nombre del producto"
                                  key={inputKey}
                                  onChange={(e) => {
                                      setNewImage(...e.target.files);
                                  }
                              }/>
                                  
                              { <TiDelete className="icon-delete" size={24} onClick={() => resetImages()}/> }
                          </div>
                      </div>
                  </Form.Group>

                  <Form.Group className="form-group">
                      <Form.Label>Codigo</Form.Label>
                      <Form.Control 
                          placeholder="ej: 18045" 
                          className="input"
                          name="code"
                          onChange={(event) => 
                              setNewPromotion({
                                  ...newPromotion,
                                  code: event.target.value
                              })
                          }
                      />
                  </Form.Group>

                  <Form.Group className="form-group" controlId="startDate">
                      <Form.Label>Fecha de inicio</Form.Label>
                      <Form.Control
                      type="date"
                      name="startDate"
                      className="input"
                      required
                      placeholder="Fecha de inicio"
                      onChange={(event) =>
                          setNewPromotion({
                          ...newPromotion,
                          startDate: event.target.value,
                          })
                      }
                      />
                  </Form.Group>

                  <Form.Group className="form-group" controlId="endDate">
                      <Form.Label>Fecha de fin</Form.Label>
                      <Form.Control
                      type="date"
                      name="endDate"
                      className="input"
                      required
                      placeholder="Fecha de Finalización"
                      onChange={(event) =>
                          setNewPromotion({
                          ...newPromotion,
                          endDate: event.target.value,
                          })
                      }
                      />
                  </Form.Group>
              </Form>
              <Row className="mb-4">
                  <Col className="text-end">
                      <Button variant="secondary" className="btn-add-promotion" onClick={handleSubmit} type="submit">
                          + Añadir
                      </Button>
                  </Col>
              </Row>
          </div>
      </div>
*/

  )
}

export default AddPromotions;