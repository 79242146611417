import { useAppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { HOME, LOGIN } from "../config/router/routes";

const baseUrl = process.env.REACT_APP_URL_BACKEND;

export const abm = (abmEntity, options = { onError: (reason) => { } }) => {
  const { onError } = options;

  const processResponse = ({ res, resolve, reject }) => {
    if (res.ok) {
      resolve(res.json());
    } else {
      res.status === 401
        ? ProcessUnauthorized()
        : processError({ error: res, reject });
    }
  };

  const ProcessUnauthorized = () => {
    let navigate = useNavigate();
    navigate(LOGIN, { replace: true });
    // console.log("hola");
  };

  const processError = ({ error, reject }) => {
    onError(error);
    reject(error);
  };

  const getAll = (entity = abmEntity) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = `${baseUrl}/${entity}`;
      fetch(url
        , {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          processResponse({ res, resolve, reject });
        })
        .catch((error) => {
          processError({ error, reject });
        });
    });
  // console.log(getAll("products"));

  const getOne = (id, entity = abmEntity) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = `${baseUrl}/${entity}/${id}`;
      fetch(url, {
        credentials: "include",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          processResponse({ res, resolve, reject });
        })
        .catch((error) => {
          processError({ error, reject });
        });
    });

  const create = (body, entity = abmEntity) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = `${baseUrl}/${entity}`;
      fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          processResponse({ res, resolve, reject });
        })
        .catch((error) => {
          processError({ error, reject });
        });
    });

  const update = async (body, id, entity = abmEntity) => {
    var method = ''
    if (entity === 'stores') {
      method = 'PATCH';
      id = body.id;
    } else {
      method = 'PUT';
    }
    const url = `${baseUrl}/${entity}/${id}`;
    delete body['image'];
    delete body['marketNetwork'];
    if (entity === 'stores') { delete body['id'] };
    delete body['phone'];
    const response = await fetch(url, {
      method,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) throw new Error();
      window.location.reload();
      return res.json();
    });
    return response;
  };

  const remove = async (id, entity = abmEntity) => {
    const url = `${baseUrl}/${entity}/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) throw new Error();
      window.location.reload();
      return res.json();
    });
    return response;
  };

  const uploadImage = async (data, type, entity = abmEntity) => {
    const url = `${baseUrl}/${entity}`;
    const formData = new FormData();
    formData.append("image", data);
    formData.append("type", type);
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    return response.json();
  };

  const uploadImageProduct = async (data, id, entity = abmEntity) => {
    const url = `${baseUrl}/${entity}/${id}`;
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("description", data.description);
    formData.append("type", data.type);
    // console.log("form data", formData.values);
    
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    return response;
  };

  const removeImage = async (productId, imageId) => {
    const url = `${baseUrl}/images/${productId}/${imageId}`;
    const response = await fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) throw new Error();
      window.location.reload();
      return res.json();
    });
    return response;
  };

  const uploadMassiveFile = async (file) => {
    const url = `${baseUrl}/files`;
    const formData = new FormData();
    formData.append("csv", file);
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    return response;
  };

  const validateUser = async (adminEmail, password, namespace, entity = abmEntity) => {
    const url = `${baseUrl}/${entity}/${namespace}`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        adminEmail,
        password
      }),
    }).then((res) => {
      if (!res.ok) throw new Error();
      return res.json();
    });
    return response;
  };

  const getStockByNamespace = async (namespace) => {
    const url = `${baseUrl}/stocks/${namespace}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) throw new Error();
      return res.json();
    });
    return response;
  };

  const updateStock = async (id, namespace, stock) => {
    const url = `${baseUrl}/stocks/${id}`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        namespace,
        stock
      }),
    }).then((res) => {
      if (!res.ok) throw new Error();
      return res.json();
    });
    return response;
  };

  return {
    getAll,
    getOne,
    create,
    update,
    remove,
    uploadImage,
    uploadImageProduct,
    removeImage,
    uploadMassiveFile,
    validateUser,
    getStockByNamespace,
    updateStock
  };
};

export const useAbm = ({ entity }) => {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const handleError = (reason) => {
    //TODO: si el error es 401 desloguear
    //appContext.signOut();
    navigate(HOME)
    console.log(reason);
  };
  return { ...abm(entity, { onError: handleError }) };
};

export const { getAll, getOne, create, update, remove, uploadImage, removeImage, uploadMassiveFile, validateUser, getStockByNamespace, updateStock } = abm();
