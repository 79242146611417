import { useCart } from "./hooks/useCart"
import "./css/asideCartEstadoPedido.css"

function CartItemOrder({ image, price, title, quantity, subtitle }) {
    function titleTrim(title) {
        const titleArray = title.split(" ")
        const titleTrim = titleArray.slice(0, 3)
        const result = titleTrim.join(" ")
        return result
    }

    return (
        <li>
            <img className="item-img" src={`data:image/png;base64,${image[0].base64}`} alt={title}></img>
            <div>
                <h2 className='cart-title'>
                    {titleTrim(title)}
                </h2>
                <h3 className="cart-subtitle"> {subtitle} </h3>

                <footer className="item-footer">
                    <strong className="price"> $ {price} ARS </strong>
                    <div>
                        <strong> {quantity} u. </strong>
                    </div>
                </footer>
            </div>
        </li>
    )
}

export function AsideCartOrder() {

    const { cart, addToCart, removeFromCart, restToCart } = useCart()
    let totalCost = 0
    let envio = 600

    return (
        <aside className='aside-cart'>
            <ul className="products-ul">
                {cart.length === 0 ?
                    <div className="empty-container">
                        <p>Tu carrito esta vacio</p>
                        <p className="p-2">!Mira nuestras promociones!</p>
                    </div>
                    : cart.map(product => (
                        <CartItemOrder
                            key={product.id}
                            addToCart={() => addToCart(product)}
                            removeFromCart={() => removeFromCart(product)}
                            restToCart={() => restToCart(product)}
                            {...product}
                            {...totalCost += (product.price * product.quantity)}
                        />
                    ))
                }
            </ul>
            <footer className="subtotal-container">
                <div className="subtotal">
                    <strong> SUBTOTAL </strong>
                    <strong>$ {totalCost.toFixed(2)} ARS </strong>
                </div>
                <div className="subtotal">
                    <strong> COSTO DE ENVÍO </strong>
                    <strong>$ {envio} ARS </strong>
                </div>
                <div className="total">
                    <strong> Total </strong>
                    <strong>$ {(totalCost + envio).toFixed(2)} ARS </strong>
                </div>
            </footer>
        </aside>
    )
}