import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaStore } from "react-icons/fa";

export const Footer = () => {
  const [city, setCity] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    const sectorId = localStorage.getItem("sectorId");

    switch (`${sectorId}`) {
      case "1":
        setColor("#5cb8b2");
        break;
      case "2":
        setColor("#ffae4d");
        break;
      case "3":
        setColor("#f3277a");
        break;
      case "4":
        setColor("ff4343");
        break;
      case "5":
        setColor("#de1994");
        break;
      default:
        setColor("#5cb8b2");
        break;
    }

    setCity(localStorage.getItem("city"));

  }, []);


  return (
    <footer id="footer">
      <Container fluid className="py-3 px-4">
        <Row className={city !== "" ? "justify-content-between" : "justify-content-end"}>
          {
            city !== "" ?
              <Col xs={12} lg={2}>
                <FaStore size={22} color={color} />
                <p className="ms-2 mb-0 align-middle d-inline-block">
                  Tienda <b>{city}</b>
                </p>
              </Col>
              :
              null
          }
          <Col xs={12} md={8} className="text-end mt-4 mt-md-0">
            <p className="mb-0">
              Copyright &copy; 2022 <b>Idraet Group</b>.
              Todos los derechos reservados.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}