import { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { abm, getAll, useAbm } from "../../services/abm";
import "../../css/promotions.css"
import ManagePromotions from "../../components/abm/promotions/ManagePromotions";
import AddPromotions from "../../components/abm/promotions/AddPromotions";
import moment from "moment";
import * as yup from 'yup';
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";

let schemaAdd = yup.object().shape({
  title: yup.string().required("Título es un campo obligatorio."),
  code: yup.string().required("Código es un campo obligatorio."),
});

function Promotions() {

  const { create } = useAbm({ entity: "promotions" });
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  const [key, setKey] = useState('manage');
  const [promotions, setPromotions] = useState([]);
  const [categories, setCategories] = useState([])
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  const administrarBreadcrumb = [
    { caption: "Promociones", href: "#" },
    { caption: "Administrar", href: "#" }
  ];

  const aniadirBreadcrumb = [
    { caption: "Promociones", href: "#" },
    { caption: "Añadir", href: "#" }
  ];

  const handleAddConfirm = async (data) => {
    const affectedProducts = data.affectedProducts ? data.affectedProducts.split(",") : [];
    const affectedAccesories = data.affectedAccesories ? data.affectedAccesories.split(",") : [];
    const relatedProducts = data.relatedProducts ? data.relatedProducts.split(",") : [];
    const imageId = await uploadImages(data.principal[0], 1);
    const body = {
      title: data.title,
      image: imageId,
      type: data.promotionType,
      code: data.code,
      startDate: moment().format('DD-MM-YYYY'),
      endDate: moment().format('DD-MM-YYYY'),
      price: data.publicPrice,
      professionalPrice: data.professionalPrice,
      percentage: parseInt(data.bonus),
      affectedProducts,
      affectedAccesories,
      relatedProducts
    }

    create(body).then(result => {
      setKey("manage");
      setBreadcrumb(administrarBreadcrumb);
    }).catch(error => {
      console.log(error)
      setError(error.headers.get("message") || "Error agregando una promocion");
    });
  };

  const handleFormCancel = () => {
    setKey("manage");
  };

  const uploadImages = async (image, typeId) => {
    const res = await abm("images").uploadImage(
      image, typeId
    );

    return res.id;
  };

  const getAllPromotionsAndCategories = () => {
    getAll("promotions")
      .then((res) => {
        setPromotions(res.promotions.map((item) => {
          if (item.image?.base64 !== undefined) {
            const imgPromotion = base64toBlob(item.image.base64);
            const filePromotion = newFile(imgPromotion, `promotion-${item.id}.png`, imgPromotion.type);
            return { ...item, id: item.id, img: filePromotion };
          }
          return { ...item, id: item.id };
        }));
      })
      .catch((err) => console.log(err));

    getAll("categories")
      .then((res) => {
        setCategories(res.categories);
      })
      .catch((err) => console.log(err));
  };

  const base64toBlob = (imgBase64) => {
    const byteCharacters = atob(imgBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'multipart/form-data' });
    return blob;
  };

  const newFile = (img, name, type) => {
    const file = new File([img], name, {
      type: type,
    });
    return file;
  };

  useEffect(() => {
    setHeaderTitle("Promociones");
    key === "manage" && getAllPromotionsAndCategories();
    if (key === "add") setBreadcrumb(aniadirBreadcrumb);
    else setBreadcrumb(administrarBreadcrumb);
  }, [key]);

  return (
    <Container className="container-promotions">
      <main>
        <section>
          <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="manage" title="Administrar">
              <ManagePromotions promotions={promotions} categories={categories} getPromotionsAndCategories={getAllPromotionsAndCategories} />
            </Tab>

            <Tab eventKey="add" title="Añadir">
              <AddPromotions
                schema={schemaAdd}
                categories={categories}
                onConfirm={handleAddConfirm}
                onCancel={handleFormCancel}
              />
            </Tab>
          </Tabs>
        </section>
      </main>
    </Container>
  );
}

export default Promotions;