import React from 'react';

const FormSuccess = () => {
    return (  
        <div className="min-vh-100 w-100 d-flex flex-column justify-content-center align-items-center">
            <div>Formulario enviado con éxito</div>
        </div>
    );
}
 
export default FormSuccess;