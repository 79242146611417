import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { useState, useRef } from "react";
import { routes } from "../config/constants";
import { useAppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { HOME } from "../config/router/routes";

function Login() {

  const appContext = useAppContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    contrasena: "",
  });

  const formRef = useRef(null);

  const sendSubmit = async () => {
    const url = routes.login;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json", "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: formData.email,
        password: formData.contrasena,
      }),
    })
      .then((res) => {
        if (!res.ok) throw new Error();
        res.json().then(resObj => {
          //console.log(resObj);
          appContext.signIn(resObj.access_token, resObj.sector_id, resObj.city, resObj.namespace, resObj.role);
          navigate(HOME);
        });
      })
      .catch((err) => {
        console.log(`[ERROR - login publico - 105] ${err}`)
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    formRef.current.classList.remove("was-validated");
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      formRef.current.classList.add("was-validated");
    } else {
      formRef.current.classList.remove("was-validated");
      sendSubmit();
    }
    setValidated(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <Container>
      <h1 className="text-center mb-5 mt-4">Login</h1>
      <Form
        ref={formRef}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              required
              name="email"
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Requerido
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Contraseña"
              required
              name="contrasena"
              onChange={(event) =>
                setFormData({ ...formData, contrasena: event.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              Requerido
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="mt-4" lg="2" type="submit">
            Login
          </Button>
        </Row>
      </Form>
    </Container>
  );
}

export default Login;
