import React from "react";
import { Link } from "react-router-dom";
import { LOGIN } from "../config/router/routes";

const Home = () => {
  return (
    <div>
      <h1>Idraet Ecommerce</h1>
      <p><Link to={LOGIN}>Iniciar sesión</Link></p>
      <p><Link to="Abm">Ir al panel</Link></p>
    </div>
  );
};

export default Home;
