import Footer from "../Footer";
import Header from "../Header";
import TrakerPage from "../TrakerPage";


export default function TrakerPageView() {
    return (
        <div>
            <Header></Header>
            <TrakerPage></TrakerPage>
            <Footer></Footer>
        </div>
    )
}
