import { useState, useRef } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Step1, Step2, Step3 } from "../components/forms/professional/index";
import { routes } from "../config/constants";
import Loading from "../components/Loading";
import FormSuccess from "../components/forms/FormSuccess";
import FormError from "../components/forms/FormSuccess";

function ProfessionalRegister() {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const FormTitles = ["DATOS PERSONALES", "DATOS DE UBICACION", "OTHER"];

  const formRef = useRef(null);

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Step1 formData={formData} setFormData={setFormData} />;
      case 1:
        return <Step2 formData={formData} setFormData={setFormData} />;
      case 2:
        return <Step3 formData={formData} setFormData={setFormData} />;
      case 3:
        return <FormSuccess />;
      case 4:
        return <FormError />;
      default:
        return <Step1 formData={formData} setFormData={setFormData} />;
    }
  };

  const [formData, setFormData] = useState({
    contrasena: "",
    email: "",
    confirmarContrasena: "",
    nombre: "",
    apellido: "",
    fechaNacimiento: "",
    dni: "",
    horaInicio: "",
    horaCierre: "",
    calle: "",
    numeroExterno: 0,
    codigoPostal: 0,
    pais: "",
    provincia: "",
    ciudad: "",
    barrio: "",
    telefono: 0,
    celular: 0,
    mostrarCelular: true,
    emailActualizaciones: "",
    aceptoDebito: false,
    aceptoCredito: false,
    puntoVenta: false,
  });

  const validarContrasena = () => {
    if (formData.contrasena !== formData.confirmarContrasena) {
      formData.confirmarContrasena = "";
      return false;
    }
    return true;
  };

  const sendSubmit = async () => {
    const url = routes.signup;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: formData.contrasena,
        email: formData.email,
        passwordVerification: formData.confirmarContrasena,
        userType: "PROFESSIONAL_SECOND",
        firstName: formData.nombre,
        lastName: formData.apellido,
        birthDate: formData.fechaNacimiento,
        identificationNumber: formData.dni,
        openTime: formData.horaInicio,
        closeTime: formData.horaCierre,
        debitAllowed: formData.aceptoDebito === "true",
        creditAllowed: formData.aceptoCredito === "true",
        salePoint: formData.puntoVenta === "true",
        emailUpdates: formData.emailActualizaciones === "true",
        street: formData.calle,
        externalNumber: formData.numeroExterno,
        zipCode: formData.codigoPostal,
        country: "Argentina",
        province: formData.provincia,
        city: formData.ciudad,
        town: formData.barrio,
        phoneNumber: formData.telefono,
        mobilePhoneNumber: formData.celular,
        showMobilePhone: formData.mostrarCelular === "true",
      }),
    })
      .then((res) => {
        setTimeout(() => {
          setPage((currPage) => currPage + 1);
        }, 400);
        res.json();
      })
      .catch((err) => console.log(`[ERROR - login publico - 105] ${err}`));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.classList.remove("was-validated");
    setIsLoading(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false || !validarContrasena()) {
      e.stopPropagation();
      formRef.current.classList.add("was-validated");
    } else {
      if (page === 2) {
        sendSubmit();
      } else {
        setTimeout(() => {
          setPage((currPage) => currPage + 1);
        }, 400);
      }
      formRef.current.classList.remove("was-validated");
    }
    setValidated(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    validarContrasena();
  };

  return (
    <Container>
      <div className="header text-center">
        <h1>{FormTitles[page]}</h1>
      </div>
      <div className="body">
        <Form
          ref={formRef}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          {PageDisplay()}
          <Row>
            {page !== 0 ? (
              <Col>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  Volver
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col>
              <Button variant="primary" type="submit">
                {page === 2 ? "Finalizar" : "Siguiente"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {isLoading ? <Loading /> : <></>}
    </Container>
  );
}

export default ProfessionalRegister;
