/* eslint-disable react/prop-types */
import { useState } from "react";
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import estilos from "./css/slider3.css"
// import imagenes2 from "../assets/imagenes-02.svg"
// import imagenes3 from "../assets/imagenes-03.svg"
// import imagenes4 from "../assets/imagenes-04.svg"

const ImgContainer = ({ imagen, description, titulos }) => {
    const imagenFiltered = imagen.filter((img) => img.description === "principal")
    // console.log(imagenFiltered[0].base64);
    return (
        <div className="img-container-3">
            <div className="contenedor-relative">
                <img src={`data:image/png;base64,${imagenFiltered[0]?.base64}`} alt="imagen" />
                <button className="button-s3"> <p>VER MAS +</p></button>
            </div>
            {/* <h3>{titulos}</h3> */}
            <div className="description-container">
                <h3>Idraet <span className="negrita">Dermopurity</span></h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default function Slider3({ product }) {

    const [imagenActual, setImagenActual] = useState(2);
    const cantidad = product?.length;

    // Return prematuro para evitar errores
    // if (!Array.isArray(product) || cantidad === 0) return;

    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 2 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 2 ? cantidad - 1 : imagenActual - 1);
    };

    return (
        <div className="slider-container-3">
            <button onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
            {product.map((product, index) => {
                return (
                    <div key={index}
                        className={
                            imagenActual === index
                                ? `${estilos.slide} ${estilos.active}`
                                : estilos.slide
                        }>
                        {imagenActual === index && (
                            <ImgContainer imagen={product.image} titulos={product.title} description={product.categorie}></ImgContainer>

                        )}
                        {imagenActual === index + 1 && (
                            <ImgContainer imagen={product.image} titulos={product.title} description={product.categorie}></ImgContainer>
                        )}
                        {imagenActual === index + 2 && (
                            <ImgContainer imagen={product.image} titulos={product.title} description={product.categorie}></ImgContainer>
                        )}
                    </div>
                );
            })}
            <button onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
        </div>
    )
}
