import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import * as immutable from 'object-path-immutable';
import './AbmForm.css';
import { abm } from '../../services/abm';
import { schemaAdd } from '../../views/abm/Stores';

export const AbmForm = ({
  item,
  schema,
  onSubmit,
  onCancel,
  confirmLabel = "Guardar",
  cancelLabel = "Cancelar",
  children,
  title,
  errorState,
}) => {
  const [validated, setValidated] = useState(false);
  const [entity, setEntity] = useState(() => item || {});
  const [error, setError] = errorState || [null, () => null];
  const [entityErrors, setEntityErrors] = useState([]);

  useEffect(() => {
    if (entityErrors.length > 0) {
      const timer = setTimeout(() => setEntityErrors([]), 8000);
      return () => clearTimeout(timer);
    }
  }, [entityErrors]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (schema === schemaAdd) {
      console.log(entity);
      try {
        // Valida los datos usando Yup
        await schema.validate(entity, { strict: true, abortEarly: false });
        // Si la validación pasa, llama al callback onSubmit
        onSubmit && onSubmit(entity);
        setValidated(true);
      } catch (errors) {
        // Mapea los errores para mostrarlos en los campos correspondientes
        setEntityErrors(errors.inner.map((error) => ({
          path: error.path,
          message: error.message,
        })));
        setValidated(false);
      }
    }
    else {
      console.log("producto");
      if (entity.purpose) {
        e.stopPropagation();
        const parseData = await buildData(entity);
        const images = {
          principal: entity.principal,
          galeria: entity.galeria,
          portada: entity.portada,
        };
        try {
          await schema.validate(parseData, { strict: true, abortEarly: false, })
          onSubmit && onSubmit(parseData, images);
          setValidated(true);
        }
        // catch (errors) {
        //   // Mapea los errores para mostrarlos en los campos correspondientes
        //   setEntityErrors(errors.inner.map((error) => ({
        //     path: error.path,
        //     message: error.message,
        //   })));
        //   setValidated(false);
        // }
        catch (errors) {
          console.log(errors);
          setEntityErrors(
            errors.inner.map((item) => ({
              path: item.path,
              message: item.message,
            }))
          );
        }
      }
      else {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        } else {
          onSubmit && onSubmit(entity);
          setEntity({});
          setValidated(true);
        }
        setValidated(false);
      }
    }

  };

  // const handleSubmit = async (e) => {
  //   console.log(entity);
  //   console.log(schema);
  //   e.preventDefault();
  //   if (entity.purpose) {
  //     e.stopPropagation();
  //     const parseData = await buildData(entity);
  //     const images = {
  //       principal: entity.principal,
  //       galeria: entity.galeria,
  //       portada: entity.portada,
  //     };
  //     try {
  //       await schema.validate(parseData, { strict: true, abortEarly: false, })
  //       onSubmit && onSubmit(parseData, images);
  //       setValidated(true);
  //     }
  //     catch (errors) {
  //       console.log(errors);
  //       setEntityErrors(
  //         errors.inner.map((item) => ({
  //           path: item.path,
  //           message: item.message,
  //         }))
  //       );
  //     };
  //   } else {
  //     const form = e.currentTarget;
  //     if (form.checkValidity() === false) {
  //       e.stopPropagation();
  //     } else {
  //       onSubmit && onSubmit(entity);
  //       setEntity({});
  //       setValidated(true);
  //     }
  //     setValidated(false);
  //   }
  // };

  /*
    const handleSubmit = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const parseData = await buildData(entity);
      const images = {
        principal: entity.principal,
        galeria: entity.galeria,
        portada: entity.portada,
      };
      schema
        .validate(parseData, {
          strict: true,
          abortEarly: false,
        })
        .then((response) => {
          onSubmit && onSubmit(parseData, images);
        })
        .catch((errors) => {
          setEntityErrors(
            errors.inner.map((item) => ({
              path: item.path,
              message: item.message,
            }))
          );
        });
    };*/

  const buildData = async (data) => {
    // let secondaryCategory = "";
    // await abm("categories")
    //   .getAll()
    //   .then((response) => {
    //     const filterCategories = response.categories.filter(
    //       (category) => !!category.parentCategory
    //     );
    //     secondaryCategory = filterCategories[parseInt(data.categorySecondary)];
    //   });

    const res = {
      id: data.id,
      division: data.division,
      categories: data.categorySecondary,
      available: data.visibilidad === "No publicado" ? false : true,
      purpose: {
        primaryTitle: data.purpose.primaryTitle,
        secondaryTitle: data.purpose.secondaryTitle,
        description: data.purpose.description,
        available: data.visibilidad === "No publicado" ? false : true,
        content: {
          volume: data.volume,
          weight: data.content,
        },
        details: data.details.split(','),
        waysToUse: data.purpose.waysToUse,
        code: parseInt(data.purpose.code),
        //price: parseInt(data.pricePublic),
        // publicPrice: parseInt(data.purpose.publicPrice),
        //professionalPrice: parseInt(data.purpose.professionalPrice),
        activePrinciples: data.principalActives,
      },
      variants: data.variants,
    };

    if (data.variants.length > 0 && (data.variants[0].title === '' || data.variants[0].title === undefined)) {
      if (data.uso === "Profesional") {
        res.purpose.price = parseInt(data.purpose.publicPrice);
      } else {
        res.purpose.publicPrice = parseInt(data.variants[0].publicPrice);
        res.purpose.professionalPrice = parseInt(data.variants[0].professionalPrice);
      }
    }

    return res;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEntity((entity) => immutable.set(entity, name, value));
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <Container className="mw-100 h-100 d-flex flex-column flex-grow-1 p-4 abm-form">
      {title && <h1 style={{ marginTop: 40, marginBottom: 50 }}>{title}</h1>}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {children &&
          children({
            onControlChange: handleInputChange,
            entity,
            entityErrors
          })}
      </Form>
      <div className={`m-alert ${error ? "m-show" : "m-hide"}`}>
        <Alert
          variant="danger"
          size="md"
          onClose={() => {
            setError(null);
          }}
        >
          {error}
        </Alert>
      </div>
      <Row>
        <Col>
          <Button variant="primary" size="md" onClick={handleSubmit}>
            {confirmLabel}
          </Button>
          <Button
            variant="danger"
            size="md"
            onClick={onCancel}
            className="mx-4"
          >
            {cancelLabel}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
