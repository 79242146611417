import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

function ModalEdit(props) {
  const {
    categories,
    promocionSeleccionada,
    setPromocionSeleccionada,
    show,
    close,
    edit,
  } = props;
  const [validated, setValidated] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      edit({
        name: promocionSeleccionada.name,
        percentage: parseInt(promocionSeleccionada.percentage),
        startDate: promocionSeleccionada.startDate,
        endDate: promocionSeleccionada.endDate,
        relations: promocionSeleccionada.relations,
      });
    }
    setValidated(true);
  };

  useEffect(() => {
    setSelectedCategories(promocionSeleccionada.relations);
  }, [promocionSeleccionada]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Editar promoción</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de la promoción"
              required
              name="name"
              value={promocionSeleccionada.name}
              onChange={(event) =>
                setPromocionSeleccionada({
                  ...promocionSeleccionada,
                  name: event.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="percentage">
            <Form.Label>Porcentaje de promoción</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={100}
              placeholder="Porcentaje de promoción"
              required
              name="percentage"
              value={promocionSeleccionada.percentage}
              onChange={(event) =>
                setPromocionSeleccionada({
                  ...promocionSeleccionada,
                  percentage: event.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="selectCategory">
            <Form.Label>Categoría</Form.Label>
            <Form.Select
              onChange={(e) => {
                let { value } = e.target;
                let category = categories.find((cat) => cat.id == value)
                
                const existCategorieSelected = selectedCategories.filter(
                  (cat) => {
                    return cat.id == category.id;
                  }
                );

                if (value !== "" && !existCategorieSelected.length) {
                  setSelectedCategories([
                    ...selectedCategories,
                    category,
                  ]);

                  setPromocionSeleccionada({
                    ...promocionSeleccionada,
                    relations: [
                      ...promocionSeleccionada.relations,
                      category,
                    ],
                  });
                }
              }}
            >
              <option value="">Seleccioná la categoría</option>
              {categories.map((category) => {

                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <div>
            {selectedCategories?.length ? (
              <Row>
                <p className="mt-3">Categorías seleccionadas</p>
                {selectedCategories.map((categorie, index) => {
                  return (
                    <Row key={Math.random()} className="mt-2">
                      <Col xs={2}>
                        <Button
                          className="btn btn-danger mr-2"
                          onClick={() => {
                            setSelectedCategories(
                              selectedCategories.filter(
                                (categorieFilter) =>
                                  categorieFilter.id != categorie.id
                              )
                            );
                            
                            setPromocionSeleccionada({
                              ...promocionSeleccionada,
                              relations: [
                                ...promocionSeleccionada.relations.filter(
                                  (categorieFilter) =>
                                    categorieFilter.id != categorie.id
                                ),
                              ],
                            });
                          }}
                        >
                          <AiFillDelete />
                        </Button>
                      </Col>
                      <Col xs={10} className="d-flex align-items-center p-0" id={index}>
                        {categorie.name}
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            ) : (
              <></>
            )}
          </div>

          <Form.Group className="mt-3" controlId="startDate">
            <Form.Label>Fecha de Inicio</Form.Label>
            <Form.Control
              type="date"
              placeholder="Fecha de Inicio"
              required
              name="startDate"
              value={promocionSeleccionada.startDate.substring(10, 0)}
              onChange={(event) =>
                setPromocionSeleccionada({
                  ...promocionSeleccionada,
                  startDate: event.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="endDate">
            <Form.Label>Fecha de Fin</Form.Label>
            <Form.Control
              type="date"
              placeholder="Fecha de Finalizacion"
              required
              name="endDate"
              value={promocionSeleccionada.endDate.substring(10, 0)}
              onChange={(event) =>
                setPromocionSeleccionada({
                  ...promocionSeleccionada,
                  endDate: event.target.value,
                })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Actualizar</Button>
          <Button variant="secondary" onClick={close}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModalEdit;
