import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import DatosPedido from '../DatosPedido'


export default function PaginaDatosPedidoView() {
    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <DatosPedido></DatosPedido>
            <Footer></Footer>
        </>
    )
}