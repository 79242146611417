import React from 'react'
import "../../css/configuracion.css"
import { ControlText } from "../../components/helpers";
import { Row, Col } from 'react-bootstrap';

export default function Configuration() {

    return (
        <Row className="small-labels">
             <Col className="col-12 col-lg-4 mt-3">
                <div className='config-container'>
                    <ControlText
                        name="configuration"
                        label="Mercadolibre Key"
                        placeholder={"Mercadolibre Key"}
                    />
                    
                </div>
                <button className='button'>actualizar</button>
             </Col>
        </Row>
    )
}
