import "./css/mediosPago.css"

export default function MediosPago() {
    return (
        <div className="medios-pago-container">
            <div className='left-container'>
                <h1>Tarjetas Mercado Pago</h1>
            </div>
            <div className='right-container'>
                <div className="title-container">
                    <p className="section-area">CARRITO DE COMPRAS</p>
                    <h2>Medios de pago</h2>
                </div>
                <form className="right-form">
                    <div className="form-header">
                        <div className="form-header-left">
                            <p>icon</p>
                            <div className="transporte-title-container">
                                <h3>TARJETA DE CRÉDITO / DÉBITO</h3>
                            </div>
                        </div>
                        <div className="form-header-right">
                            <div className="checkbox">
                                <strong>$600ARS</strong>
                                <label class="option-checkbox-container">
                                    <input type="checkbox" />
                                    <div class="checkmark-option"></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-body">
                        <input className="input-text" type="text" placeholder="Número de tarjeta" />
                        <input className="input-text" type="text" placeholder="Titular de la tarjeta" />
                        <input className="input-text" type="text" placeholder="Vencimiento (MM/AA)" />
                        <input className="input-text" type="text" placeholder="Código de seguridad" />
                        <select name="hasta" id="hasta">
                            <option value="hasta">cuotas</option>
                        </select>
                        <input className="input-text" type="text" placeholder="Provincia" />
                    </div>
                    <div className="form-footer">
                        <div className="footer-left">
                            <div className="input-checkox-container">
                                <div class="checkbox-wrapper">
                                    <input id="_checkbox-26" type="checkbox" />
                                    <label for="_checkbox-26">
                                        <div class="tick_mark"></div>
                                    </label>
                                </div>
                                <label>Guardar tarjeta para futuras compras</label>
                            </div>
                        </div>
                        <div className="footer-right">
                            <label>Tarjetas aceptadas por Mercado Pago:</label>
                            <input className="input-text" type="text" placeholder="gg" />
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
