import "./App.css";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AppRoutes } from "./AppRoutes";
import { useCookies } from 'react-cookie';

const defAppContext = ({ token, setToken, role, setRole }) => {

  const load = async () => {
    const localToken = localStorage.getItem("token");
    const localRole = localStorage.getItem("role");
    if (localToken) {
      setToken(true);
      setRole(localRole);
    } else {
      setToken(false);
      setRole(null);
    }
  };

  const signOut = () => {
    setToken(null);
    setRole(null);
    localStorage.removeItem('auth');
    localStorage.removeItem('token');

  };

  const signIn = (token, sectorId, city, namespace, role) => {
    setToken(true);
    setRole(role);

    localStorage.setItem('role', role);
    localStorage.setItem('auth', true);
    localStorage.setItem('token', token);
    localStorage.setItem('sectorId', sectorId);
    localStorage.setItem("city", city);
    localStorage.setItem("namespace", namespace);
   // localStorage.setItem("email", email);
  };

  return { auth: token, isAuth: token, role, signOut, signIn, load };
};

const AppContext = createContext(defAppContext({}));
export const useAppContext = () => useContext(AppContext);

function App(props) {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const context = defAppContext({ token, setToken, role, setRole });


  useEffect(() => {
    context.load();
  }, []);

  return (
    <AppContext.Provider value={context}>
      <AppRoutes />
    </AppContext.Provider>
  );
}

export default App;
