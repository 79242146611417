import React from 'react'
import "../css/initBoxes.css"

export default function InitBoxes({ icon, number, text }) {
  return (
    <div className='box'>
      <div className='box-img'>{icon}</div>
      <div className='text-number-container'>
        <div className='box-number'> {number} </div>
        <div className='box-text'> {text} </div>
      </div>
    </div>

  )
}
