import React, { useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
const objectPath = require("object-path");

export const ControlText = ({
  name,
  label,
  placeholder,
  disabled = false,
  onChange,
  data,
  start,
  end,
  errors = [],
  ...options
}) => {
  const target = useRef();
  const value = data ? objectPath(data).get(name, "") : "";
  const error = errors.find((e) => e.path === name);
  return (
    <>
      <div
        ref={target}
        className="mb-3 row mb-3 row w-100 d-flex flex-row"
        style={{ minHeight: "35px" }}
      >
        <label
          htmlFor={name}
          className="col-sm-4 col-form-label fs-6 fst-italic truncate"
        >
          {label}
        </label>
        <div
          className={`col-sm-8 d-flex flex-row p-0 ${disabled ? "disable-input" : ""
            }`}
        >
          {start}
          <input
            type="text"
            id={name}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            {...options}
            className="flex-grow-1 max-h-4 px-2"
            style={{
              border: "0px solid white",
              maxHeight: "36px",
              height: "36px",
            }}
            disabled={disabled}
          />
          {end}
        </div>
      </div>
      <Overlay target={target.current} show={error} placement="right">
        {(props) => (
          <Tooltip
            id={`tooltip-${name}`}
            {...{ ...props, style: { ...(props.style || {}), opacity: 0.8 } }}
          >
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

// import React, { useRef } from "react";
// import { Form, Overlay, Tooltip } from "react-bootstrap";

// export const ControlText = ({ name, label, errors, data, onChange, ...props }) => {
//   const target = useRef(null); // Referencia para el Overlay
//   const error = errors.find((err) => err.path === name); // Encuentra el error correspondiente

//   return (
//     <>
//       <Form.Group controlId={name} ref={target}>
//         <Form.Label>{label}</Form.Label>
//         <Form.Control
//           type="text"
//           name={name}
//           value={data[name] || ""}
//           onChange={onChange}
//           isInvalid={!!error}
//           {...props}
//         />
//         {error && (
//           <Form.Control.Feedback type="invalid">
//             {error.message}
//           </Form.Control.Feedback>
//         )}
//       </Form.Group>

//       {/* Mostrar Tooltip de error si existe */}
//       <Overlay target={target.current} show={!!error} placement="right">
//         {(props) => (
//           <Tooltip
//             id={`tooltip-${name}`}
//             {...props}
//             style={{ ...props.style, opacity: 0.8 }}
//           >
//             {error?.message}
//           </Tooltip>
//         )}
//       </Overlay>
//     </>
//   );
// };

