export const RemoveIcon = ({
  action,
  className = "position-absolute start-100 remove-file",
  multiple = false,
}) => {
  return (
    <span className={className} onClick={action}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={
          "rounded-circle" +
          (!multiple
            ? " icon bg-danger text-white me-2"
            : " icon-sm bg-white text-black")
        }
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  );
};
