import { useEffect, useState, useRef } from "react"
import { getAll } from '../../../../services/abm'

export default function usePromotions() {
    const [promotions, setPromotions] = useState([])
    const componentMounted = useRef(true)

    useEffect(() => {
        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])

    useEffect(() => {
        getAll("promotions")
            .then((res) => {
                if (componentMounted.current) {
                    console.log(res.promotions);
                    setPromotions(res.promotions);
                }
            })
        // No necesitas especificar dependencias aquí, ya que sólo deseas que este efecto se ejecute una vez.
    }, [])

    return { promotions }
}
