import React from "react";
import { useState } from "react";
import { Row } from "react-bootstrap"
import { create } from "../../../services/abm";
import { ControlText } from "../../helpers";
import { AbmForm } from "../AbmForm";


const AddAccessories = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState}) => {
    const [validated, setValidated] = useState(false);

      const [newAccessory, setNewAccessory] = useState({
        title: "",
      });

      const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        } else {
          add({
            title: newAccessory.title
          });
        }
        setNewAccessory("");
        setValidated(true);
      };

    const add = (newAccessory) => {
        create(newAccessory, "accesories");
      };        

    return( <AbmForm onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} title={title} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => (
          <>
          <Row>
                <ControlText name="title" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
          </Row>
        </>
      )}
      </AbmForm>
        
    )
}

export default AddAccessories;