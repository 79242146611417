/* eslint-disable react/prop-types */
import { useState } from "react";
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import estilos from "./css/sliderColor.css"
import { getOne } from "../../../services/abm";

const ColorContainer = ({ color, description, titulos, id }) => {
    const changeVariant = ()=>{
        console.log(id);
        
    }
    return (
        <div className="color-container" onClick={changeVariant}>
            <div className="div" style={{ backgroundColor: `${color}` }}> </div>
            {/* <input type="checkbox" className="checkbox" style={{ backgroundColor: `${color}` }} /> */}
            <label></label>
            <div className="color-info">
                <p>{titulos}</p>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default function SliderColors({ variant }) {
    console.log(variant);
    const [imagenActual, setImagenActual] = useState(3);
    const cantidad = variant?.length;

    // Return prematuro para evitar errores
    if (!Array.isArray(variant) || cantidad === 0) return;

    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 3 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 3 ? cantidad - 1 : imagenActual - 1);
    };
    console.log(variant);
    
    return (
        <div className="color-slider-container">
            <button className="button-color" onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
            {variant.map((color, index) => {
                return (
                    <div key={index}
                        className={
                            imagenActual === index
                                ? `${estilos.slide} ${estilos.active}`
                                : estilos.slide
                        }
                    >
                        {imagenActual === index && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title} id={color.id}></ColorContainer>
                        )}
                        {imagenActual === index + 1 && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title} id={color.id}></ColorContainer>
                        )}
                        {imagenActual === index + 2 && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title} id={color.id}></ColorContainer>
                        )}
                        {imagenActual === index + 3 && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title} id={color.id}></ColorContainer>
                        )}
                        {/* {imagenActual === index + 4 && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title}></ColorContainer>
                        )}
                        {imagenActual === index + 5 && (
                            <ColorContainer color={color.colour} description={color.code} titulos={color.title}></ColorContainer>
                        )} */}


                    </div>
                );
            })}
            <button className="button-color" onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
        </div>
    )
}
