import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { MdAlternateEmail } from "react-icons/md";
import { TiPlus } from "react-icons/ti";
import { abm } from "../../../services/abm";
import { ControlSelect, ControlText, Decorator, ControlTagsSelect } from "../../helpers";
import { AbmForm } from "../AbmForm";


export const StoreForm = ({ item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState }) => {

  const [modelosNegocio, setModelosNegocio] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [stores, setStores] = useState([]);



  useEffect(() => {
    abm("sectors").getAll().then(data => {
      setSectors(data.results.map(item => ({ value: item.name, label: item.name })));
    });
    abm("stores").getAll().then(data => {
      setStores(data.results.map(item => ({ value: item.namespace, label: item.namespace })));
    });
  }, [])

  return (
    <AbmForm onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} title={title} errorState={errorState}>
      {({ onControlChange, entity, entityErrors }) => (
        <>
          <Row>
            <Col className="col-12 col-lg-4 mt-3">
              <p><b>DATOS DE LA TIENDA</b></p>
              <ControlText name="name" label="Tienda" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={"Nombre de la tienda"} required />
              <ControlText name="namespace" label="Subdominio" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={"Subdominio"} />
              <ControlSelect name="sector" label="Modelo de negocio" errors={entityErrors} data={entity} onChange={onControlChange} options={sectors} placeholder="Seleccioná un modelo" />
              <ControlTagsSelect name="relatedStores" label="Red de comercio" errors={entityErrors} data={entity} onChange={onControlChange} options={stores} placeholder="Red de comercio" />

            </Col>
            <Col className="col-12 col-lg-4 mt-3">
              <p><b>ACCESO</b></p>
              <ControlText name="adminEmail" label="Email" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
              <ControlText name="password" label="Contraseña" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
            </Col>
            <Col className="col-12 col-lg-4 mt-3">
              <p><b>REDES SOCIALES</b></p>
              <ControlText name="socialData.mobilePhoneNumber" label="WhatsApp" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlText name="socialData.instagram" label="Instagram" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} start={<Decorator variant="primary"><MdAlternateEmail size={20} /></Decorator>} />
              <ControlText name="socialData.facebook" label="Facebook" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} start={<Decorator variant="primary"><MdAlternateEmail size={20} /></Decorator>} />
            </Col>

          </Row>
          <Row>
            <Col className="col-12 col-lg-4 mt-3">
              <p><b>DATOS DE UBICACIÓN</b></p>
              <ControlText name="address.street" label="Dirección" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlText name="address.externalNumber" label="Número" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlText name="address.zipCode" label="Código postal" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlText name="address.city" label="Ciudad" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
              <ControlText name="address.province" label="Provincia" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} />
            </Col>
          </Row>
        </>
      )}
    </AbmForm>
  );
}



