import { React, useContext, useEffect, useState } from "react";
import InitBoxes from "../components/InitBoxes";
import { PrivateLayoutContext } from "../components/layout/PrivateLayout";
import "../css/init.css"
import { MarketIcon } from "../components/icons/MarketIcon";
import { AcademicIcon } from "../components/icons/AcademicIcon";
import { ServiceIcon } from "../components/icons/ServiceIcon";
import { ProfesionalIcon } from "../components/icons/profesionalIcon";
import { ProductsIcon } from "../components/icons/ProductsIcon";
import { PromotionIcon } from "../components/icons/PromotionIcon";
import { abm } from "../services/abm";
const Init = () => {

  const [init, setInit] = useState([]);
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  const breadcrumb = [
    { caption: "inicio", href: "#" },
  ]

  useEffect(()=>{
    setBreadcrumb(breadcrumb);
    setHeaderTitle('Inicio');
    abm("init").getAll().then(data => {
      console.log(data);
      setInit(data);
    });
  }, [])
  
  return (
    <div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ fontSize: '24px', marginTop: '30px', marginBottom: '50px' }}>Bienvenidos a Idraet Online Shopping</h1>
      </div>
      <div className="init-container">
        <div className="boxes-container">
          <InitBoxes icon={<MarketIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.activesStores} text={"tiendas activas"}></InitBoxes>
          <InitBoxes icon={<AcademicIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.academicOffers} text={"Ofertas Académicas disponibles"}></InitBoxes>
          <InitBoxes icon={<ProductsIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.products} text={"Productos cargados"}></InitBoxes>
          <InitBoxes icon={<ProfesionalIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.professionals} text={"Profesionales registrados"}></InitBoxes>
          <InitBoxes icon={<ServiceIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.activesServices} text={"Servicios activos"}></InitBoxes>
          <InitBoxes icon={<PromotionIcon height={100} width={100} fill={"#5cb8b2"} />} number={init.currentPromotions} text={"Promociones vigentes"}></InitBoxes>
        </div>
      </div>

    </div>
  );
};

export default Init;
