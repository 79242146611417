import { useState } from "react";
import SvgBack from "../assets/Back"
import SvgNext from "../assets/Next"
import estilos from "./css/sliderNav.css";

export function NavContainer({ category }) {

    return (
        <p className="category">{category}</p>
    )
}

export function SliderNav({ navCategory }) {
    const [imagenActual, setImagenActual] = useState(5);
    const cantidad = navCategory?.length;

    // Return prematuro para evitar errores
    if (!Array.isArray(navCategory) || cantidad === 0) return;

    const siguienteImagen = () => {
        setImagenActual(imagenActual === cantidad - 1 ? 5 : imagenActual + 1);
    };

    const anteriorImagen = () => {
        setImagenActual(imagenActual === 5 ? cantidad - 1 : imagenActual - 1);
    };
    return (

        <div className="slider-container-nav">
            {navCategory.map((category, index) => {
                return (
                    <div key={index}
                        className={
                            imagenActual === index
                                ? `${estilos.slide} ${estilos.active}`
                                : estilos.slide
                        }>
                        {imagenActual === index && (
                            <NavContainer category={category}></NavContainer>
                        )}
                        {imagenActual === index + 1 && (
                            <NavContainer category={category}></NavContainer>
                        )}
                        {imagenActual === index + 2 && (
                            <NavContainer category={category}></NavContainer>
                        )}
                        {imagenActual === index + 3 && (
                            <NavContainer category={category}></NavContainer>
                        )}
                        {imagenActual === index + 4 && (
                            <NavContainer category={category}></NavContainer>
                        )}
                        {imagenActual === index + 5 && (
                            <NavContainer category={category}></NavContainer>
                        )}
                    </div>
                );
            })}
            <div className="button-container">
                <button onClick={anteriorImagen}><SvgBack height={15} width={15} fill={"red"}></SvgBack></button>
                <button onClick={siguienteImagen}><SvgNext height={15} width={15} fill={"red"}></SvgNext></button>
            </div>
        </div>
    )
}