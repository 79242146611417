import React from "react";

const FormError = () => {
  return (
    <div className="min-vh-100 w-100 d-flex flex-column justify-content-center align-items-center">
      <div>Ocurrió un error al enviar el formulario</div>
    </div>
  );
};

export default FormError;
