import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ABM_CONFIGURATION, ABM_ACADEMIC_OFFER, ABM_CATEGORIES, ABM_PRODUCTS, ABM_PROFESSIONALS, ABM_PROMOTIONS, ABM_SERVICES, ABM_SHOPS, HOME, LOGOUT, ABM_ACCESSORIES, INIT, ABM_ORDER, ABM_SECTORS } from "../../config/router/routes";
import { useAppContext } from "../../App";

export const SideDrawer = () => {

  const sectorsThemes = [
    {
      logoSrc: "/assets/images/logo.png",
      activeColor: "#5cb8b2",
      bgColor: "#363b3f",
      textColor: "#ffffff"
    },
    {
      logoSrc: "/assets/images/logo.png",
      activeColor: "#ffae4d",
      bgColor: "#525656",
      textColor: "#ffffff"
    },
    {
      logoSrc: "/assets/images/logo3.svg",
      activeColor: "#ff52a1",
      bgColor: "#2b2b38",
      textColor: "#ffffff"
    },
    {
      logoSrc: "/assets/images/logo4.svg",
      activeColor: "#ff4343",
      bgColor: "#222222 ",
      textColor: "#797979"
    },
    {
      logoSrc: "/assets/images/logo5.svg",
      activeColor: "#e328b8",
      bgColor: "#ffffff",
      textColor: "#797979"
    }
  ];

  const [sectorTheme, setSectorTheme] = useState(sectorsThemes[0]);
  const [sectorId, setSectorId] = useState(0);

  const activeColor = {
    color: sectorTheme.activeColor
  }
  const textColor = {
    color: sectorTheme.textColor
  }

  useEffect(() => {
    const sectorId = localStorage.getItem("sectorId");
    setSectorId(parseInt(sectorId));

    switch (sectorId) {
      case "1":
        setSectorTheme(sectorsThemes[0]);
        break;
      case "2":
        setSectorTheme(sectorsThemes[1]);
        break;
      case "3":
        setSectorTheme(sectorsThemes[2]);
        break;
      case "4":
        setSectorTheme(sectorsThemes[3]);
        break;
      case "5":
        setSectorTheme(sectorsThemes[4]);
        break;
      default:
        setSectorTheme(sectorsThemes[0]);
        break;
    }

  }, []);

  const navigate = useNavigate();
  const appContext = useAppContext();

  const logout = () => {
    appContext.signOut();
    navigate(HOME)
  }

  return (
    <div id="sidedrawer" className="mui--no-user-select" style={{ backgroundColor: sectorTheme.bgColor }}>
      <div id="sidedrawer-brand" className="mui--appbar-line-height p-4">
        <img src={sectorTheme.logoSrc} alt="Logo de la tienda" width="100%" />
      </div>
      <div className="sidedrawer-buttons-wrapper h-100">
        <div style={{ flex: 1 }}></div>
        <ul className="sidedrawer-buttons">
          <li >
            <NavLink to={INIT} style={({ isActive }) => isActive ? activeColor : textColor}>Inicio</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_SHOPS} style={({ isActive }) => isActive ? activeColor : textColor} >Tiendas</NavLink>
          </li>
          <li>
            <NavLink to={ABM_PRODUCTS} style={({ isActive }) => isActive ? activeColor : textColor}>Productos</NavLink>
          </li>
          <li>
            <NavLink to={ABM_SERVICES} style={({ isActive }) => isActive ? activeColor : textColor}>Servicios</NavLink>
          </li>
          <li>
            <NavLink to={ABM_ACADEMIC_OFFER} style={({ isActive }) => isActive ? activeColor : textColor}>Oferta Académica</NavLink>
          </li>
          <li>
            <NavLink to={ABM_PROFESSIONALS} style={({ isActive }) => isActive ? activeColor : textColor}>Profesionales</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_ORDER} style={({ isActive }) => isActive ? activeColor : textColor}>Ordenes</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_PROMOTIONS} style={({ isActive }) => isActive ? activeColor : textColor}>Promociones</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_CATEGORIES} style={({ isActive }) => isActive ? activeColor : textColor}>Categorias</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_ACCESSORIES} style={({ isActive }) => isActive ? activeColor : textColor}>Accesorios</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_SECTORS} style={({ isActive }) => isActive ? activeColor : textColor}>Sectores</NavLink>
          </li>
          <li style={{ display: `${sectorId !== 0 && 'none'}` }}>
            <NavLink to={ABM_CONFIGURATION} style={({ isActive }) => isActive ? activeColor : textColor}>Configuracion</NavLink>
          </li>

        </ul>
        <div style={{ flex: 1 }}></div>
        <ul className="sidedrawer-buttons">
          <li>
            {/* <NavLink to={LOGOUT} style={textColor}>Cerrar sesión</NavLink> */}
            <button onClick={logout}>Cerrar sesión</button>
          </li>
        </ul>
      </div>
    </div>
  )
}