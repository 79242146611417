import "./css/estadoPedido.css"
import { AsideCartOrder } from "./AsideCartOrder"
import { useState } from "react"

const MediosPagoOptionOrder = ({ icon, title, subTitle, icon2, container }) => {
    return (
        <div className={container}>
            <div className="left-container">
                <p> {icon} </p>
                <div className="option-title-container">
                    <h3> {title} </h3>
                    <small> {subTitle} </small>
                </div>
            </div>
            <div className="checkbox-2">
                <p> {icon2} </p>
            </div>
        </div>
    )
}

const PagoDigitales = ({ pagoState }) => {
    // {
    //     subTitle: "20 / 07 / 2022 - 18:23HS",
    //     pago: "EN ESPERA DE PAGO",
    //     pedido: "PREPARANDO PEDIDO",
    //     envio: "PEDIDO EN CAMINO",
    //     medioPago: "DIGITAL"
    // }
    return (
        <>
            {pagoState.state === 1 &&
                <>
                    <MediosPagoOptionOrder
                        icon={"iconP-1"}
                        title={"EN ESPERA DE PAGO"}
                        subTitle={"Aguardando a que el pago sea acreditado."}
                        icon2={"iconP2-1"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconPe-1"}
                        title={"PREPARANDO PEDIDO"}
                        subTitle={"En espera"}
                        icon2={"iconPe2-1"}
                        container={"contenedor-option-grey"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconE-1"}
                        title={"PEDIDO EN CAMINO"}
                        subTitle={"En espera"}
                        icon2={"iconE2-1"}
                        container={"contenedor-option-grey"}
                    />
                </>
            }
            {pagoState.state === 2 &&
                <>
                    <MediosPagoOptionOrder
                        icon={"iconP-2"}
                        title={"PAGO CONFIRMADO"}
                        subTitle={pagoState.pagoSubTitle}
                        icon2={"iconP2-2"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconPe-2"}
                        title={"PREPARANDO PEDIDO"}
                        subTitle={"¡Estamos preparando tus productos seleccionados!"}
                        icon2={"iconPe2-1"}
                        container={"contenedor-option"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconE-1"}
                        title={"PEDIDO EN CAMINO"}
                        subTitle={"En espera "}
                        icon2={"iconE2-1"}
                        container={"contenedor-option-grey"}
                    />
                </>
            }
            {pagoState.state === 3 &&
                <>
                    <MediosPagoOptionOrder
                        icon={"iconP-2"}
                        title={"PAGO CONFIRMADO"}
                        subTitle={pagoState.pagoSubTitle}
                        icon2={"iconP2-2"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconPe-3"}
                        title={"PEDIDO LISTO"}
                        subTitle={pagoState.pedidoSubTitle}
                        icon2={"iconPe2-3"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconE-2"}
                        title={"PEDIDO EN CAMINO"}
                        subTitle={"¡Tu pedido se encuentra en camino a tu domicilio!"}
                        icon2={"iconE2-2"}
                        container={"contenedor-option"}
                    />
                </>
            }
            {pagoState.state === 4 &&
                <>
                    <MediosPagoOptionOrder
                        icon={"iconP-2"}
                        title={"PAGO CONFIRMADO"}
                        subTitle={pagoState.pagosubTitle}
                        icon2={"iconP2-2"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconPe-3"}
                        title={"PEDIDO LISTO"}
                        subTitle={pagoState.pedidoSubTitle}
                        icon2={"iconPe2-3"}
                        container={"contenedor-option-green"}
                    />
                    <MediosPagoOptionOrder
                        icon={"iconE-3"}
                        title={"PEDIDO ENTREGADO"}
                        subTitle={pagoState.envioSubTitle}
                        icon2={"iconE2-3"}
                        container={"contenedor-option-green"}
                    />
                </>
            }
        </>
    )
}
const PagoEfectivo = ({ pagoState }) => {
    return (
        <>
            <MediosPagoOptionOrder
                icon={"icon"}
                title={"PAGO CONFIRMADO"}
                subTitle={"20 / 07 / 2022 - 18:23HS"}
                icon2={"icon2"}
                container={"contenedor-option-green"}
                estado={pagoState}
            />
            <MediosPagoOptionOrder
                icon={"icon"}
                title={"PAGO CONFIRMADO"}
                subTitle={"20 / 07 / 2022 - 18:23HS"}
                icon2={"icon2"}
                container={"contenedor-option"}
                estado={pagoState}
            />
        </>
    )
}

export default function EstadoPedido() {

    const [pagoState, setPagoState] = useState(
        {
            state: 2,
            pagoSubTitle: "20 / 07 / 2022 - 18:23HS",
            pedidoSubTitle: "21 / 07 / 2022 - 18:23HS",
            envioSubTitle: "20 / 07 / 2022 - 18:23HS",
            medioPago: "DIGITAL"
        }
    )

    return (
        <div className="checkout-container-order">
            <div className="checkout-order-title-container">
                <h1 className="title-order">Estado de pedido</h1>
            </div>
            <div className="checkout">

                <div className='columna-izquierda-order'>
                    <p className="subtitle-order"> ORDEN: #1234</p>
                    <div className="medios-entrega-container">
                        {pagoState.medioPago === "DIGITAL"
                            ? <PagoDigitales pagoState={pagoState}></PagoDigitales>
                            : <PagoEfectivo pagoState={pagoState}></PagoEfectivo>}
                        <div className="footer-order">
                            <small className="small-order">Te enviamos un email a <a className="mail">rparasole@idraet.com.ar</a> con un link a esta
                                página para que puedas seguir la entrega de tu compra.
                            </small>
                            <button className="button-order">Ayuda</button>
                        </div>
                    </div>
                </div>

                <div className='columna-derecha'>
                    <h2>PRODUCTOS</h2>
                    <AsideCartOrder></AsideCartOrder>
                </div>
            </div>
        </div>
    )
}
