import React from "react";
import { Col, Row, Table as BsTable } from "react-bootstrap";

export const Table = ({ rows = [], columns = [], actions = [], sectorId, noItemsMessage = "No se encontraron items para mostrar" }) => {
  return (
    <BsTable responsive className={`table-index ${sectorId === 5 ? "quinary" : ""} text-center`}>
      <thead>
        <tr>
          {
            columns.map(column => <th key={column.field} style={column.field === "title" ? { width: "350px" } : null}>{column.caption}</th>)
          }
        </tr>
      </thead>
      <tbody>
        {rows && rows.map(row => (
          <tr key={`row${row.id}`} className="row-index">
            {columns.map((column, index) => (
              <td
                key={`cell${column.field}${row.id}`}
                className={
                  `align-middle py-3${sectorId !== 1 && index === 0 ?
                    " ps-3"
                    : sectorId !== 1 && index === columns.length - 1 ?
                      " pe-3"
                      : ""}`}
                style={column.field === "visible" ? { width: "5%" } : null}
              >
                {column.render ? column.render(row) : row[column.field]}
              </td>
            ))}
            {
              actions ?
                <td className="align-middle">
                  <Row className="justify-content-center">
                    {actions({ row }).map(action => (
                      <Col key={`cell${action.name}${row.id}`} className="text-center">
                        <a
                          className="action"
                          href={action.name}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            action.onClick(row);
                          }}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {action.icon} <span style={{ paddingLeft: 6 }}>{action.caption}</span>
                        </a>
                      </Col>
                    ))}
                  </Row>
                </td>
                :
                null
            }
          </tr>
        ))}
        {
          !rows &&
          <tr className="text-center">
            <td colSpan={columns.length + 1}>{noItemsMessage}</td>
          </tr>
        }
      </tbody>
    </BsTable>
  );
};

