import { Modal, Button, Form } from "react-bootstrap";

import { useState } from "react";

function ModalEdit(props) {
  const { accesorioSeleccionada, setAccesorioSeleccionada, show, edit, close } =
    props;
  const [validated, setValidated] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      edit({title: accesorioSeleccionada.title});
    }
    setAccesorioSeleccionada("");
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Editar accesorio</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="title">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              placeholder="Accesorio"
              required
              name="title"
              value={accesorioSeleccionada.title}
              onChange={(event) =>
                setAccesorioSeleccionada({
                  ...accesorioSeleccionada,
                  title: event.target.value,
                })
              }
            />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Actualizar</Button>
          <Button onClick={close} variant="secondary">
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModalEdit;
