import React, { useContext } from "react";
import { LogInContext } from "../context/LogInContext";

export const UseAuth = () => {
    const context = useContext(LogInContext);
    const { isOpen, Auth, openModal, closeModal, handleAuth } = useContext(LogInContext);

    if (!context || typeof context !== 'object') {
        throw new Error("Estás fuera del alcance del proveedor");
    }

    return ({ isOpen, Auth, openModal, closeModal, handleAuth })
};
