import { Row, Col, Form } from "react-bootstrap";

function Step1({formData, setFormData}) {

  return (
    <div>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="formBasicName">
          <Form.Label>Nombre</Form.Label>
          <Form.Control 
          type="text" 
          placeholder="Nombre" 
          required  
          name="nombre"
          value={formData.nombre}
          onChange={(event) => 
            setFormData({...formData, nombre: event.target.value})
          }
          />
          <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formBasicLastName">
          <Form.Label>Apellido</Form.Label>
          <Form.Control 
          type="text" 
          placeholder="Apellido" 
          required  
          name="apellido"
          value={formData.apellido}
          onChange={(event) => 
            setFormData({...formData, apellido: event.target.value})
          }
          />
          <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="formBasicDate">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control 
            type="date" 
            required  
            name="fechaNacimiento"
            value={formData.fechaNacimiento}
            onChange={(event) => 
              setFormData({...formData, fechaNacimiento: event.target.value})
            }
            />
            <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3" aria-label="DNI">
            <Form.Label>DNI</Form.Label>
            <Form.Control 
            type="number" 
            placeholder="DNI" 
            required  
            name="dni"
            value={formData.dni}
            onChange={(event) => 
              setFormData({...formData, dni: event.target.value})
            }
            />
            <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3" aria-label="Email" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control 
            type="email" 
            placeholder="Email" 
            required  
            name="email"
            value={formData.email}
            onChange={(event) => 
              setFormData({...formData, email: event.target.value})
            }
            />
            <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" aria-label="Contraseña" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control 
            type="password" 
            placeholder="Contraseña" 
            required  
            name="contrasena"
            value={formData.contrasena} 
            onChange={(event) => 
              setFormData({...formData, contrasena: event.target.value})
            }
            />
            <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
            <Form.Group className="mb-4" aria-label="Contraseña" controlId="formBasicConfirmPassword">
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control 
              type="password" 
              placeholder="Confirmar contraseña" 
              required  
              name="confirmarContrasena" 
              value={formData.confirmarContrasena} 
              onChange={(event) => 
                setFormData({...formData, confirmarContrasena: event.target.value})
              }
              />
              <Form.Control.Feedback type="invalid">
                {formData.contrasena !== formData.confirmarContrasena ? (
                  'Las contraseñas deben ser iguales'
                ):(
                  'Requerido'
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
      </Row>
    </div>
  );
}

export default Step1;

