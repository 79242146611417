import React, { useContext } from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { PrivateLayoutContext } from "./PrivateLayout";

export const Header = () => {

  const { headerTitle, breadcrumb } = useContext(PrivateLayoutContext);
  const sectorId = localStorage.getItem("sectorId");

  let breadcrumbItemClass = "";

  switch (`${sectorId}`) {
    case "1":
      breadcrumbItemClass = "breadcrumb-item-primary";
      break;
    case "2":
      breadcrumbItemClass = "breadcrumb-item-secondary";
      break;
    case "3":
      breadcrumbItemClass = "breadcrumb-item-tertiary";
      break;
    case "4":
      breadcrumbItemClass = "breadcrumb-item-quaternary";
      break;
    case "5":
      breadcrumbItemClass = "breadcrumb-item-quinary";
      break;
    default:
      breadcrumbItemClass = "breadcrumb-item-primary";
      break;
  }
  return (
    <header id="header">
      <div className="mui-appbar mui--appbar-line-height mui--appbar-height px-4 d-flex flex-row">
        <div className="mui-container-fluid d-flex align-items-center" style={{ flex: 1 }}>
          <h2>{headerTitle}</h2>
        </div>
        {breadcrumb &&
          <Breadcrumb>
            {breadcrumb.map(item => (
              <BreadcrumbItem className={breadcrumbItemClass} key={item.caption} href={item.href}>{item.caption}</BreadcrumbItem>
            ))}
          </Breadcrumb>
        }
      </div>
    </header>
  )
}