import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header, HeaderContext } from "./Header";
import { SideDrawer } from "./SideDrawer";

export const PrivateLayoutContext = createContext({
  headerTitle: "",
  setHeaderTitle: () => { },
  breadcrumb: [],
  setBreadcrumb: () => { },
  subHeaderActions: [],
  setSubHeaderActions: () => { },
});

export const PrivateLayout = ({ children, sectorId }) => {
  const [headerTitle, setHeaderTitle] = useState("Titulo");
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [subHeaderActions, setSubHeaderActions] = useState([]);

  return (
    <PrivateLayoutContext.Provider value={{ headerTitle, setHeaderTitle, breadcrumb, setBreadcrumb, subHeaderActions, setSubHeaderActions }}>
      <SideDrawer />
      <Header />
      <div id="content-wrapper" className="d-flex flex-column">
        <div className="mui--appbar-height"></div>
        <Outlet />
      </div>
      <Footer />
    </PrivateLayoutContext.Provider>
  );
}