import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Nav2 from '../Nav2'
import PaginaProducto1 from '../PaginaProducto1'
import { useFilters } from '../hooks/useFilters'

export default function PaginaProductoView() {
    const { mapedProducts } = useFilters()

    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <PaginaProducto1 product={mapedProducts} ></PaginaProducto1>
            <Footer></Footer>
        </>
    )
}
