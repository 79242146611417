import { SimpleControlText } from "../../../helpers/SimpleControlText";

export const Content = ({ entityErrors, entity, onControlChange }) => {
  return (
    <div className="mb-3 w-100 d-flex flex-row">
      <div className={"col-sm-4 col-form-label fs-6 fst-italic "}>
        Contenido
      </div>
      <div className="col-sm-8 row d-flex flex-row justify-content-between">
        <div className="col-sm-6 pe-2 ps-0">
          <SimpleControlText
            name={"content"}
            errors={entityErrors}
            data={entity}
            type="text"
            onChange={onControlChange}
            placeholder={"Contenido"}
            disabled={false}
          />
        </div>
        <div className="col-sm-6 px-2">
          <SimpleControlText
            name={"volume"}
            errors={entityErrors}
            data={entity}
            type="text"
            onChange={onControlChange}
            placeholder={"Volumen"}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};
