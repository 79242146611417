//ECOMMERCE
export const LOGIN = "login";
export const PUBLIC_REGISTER = "registro/publico";
export const PROFESSIONAL_REGISTER = "registro/profesional";
export const HOME = "/";
export const LOGOUT = "logout";
export const HOME_LANDING = "landing"
export const PRODUCTS_PAGE = "products"
export const PRODUCT_PAGE = "producto/:id"
export const INIT = "inicio";
export const PROMOS = "promos"
export const CHECKOUT = "checkout"
export const ESTADO_PEDIDO = "pedido-estado"
export const MEDIOS_PAGO = "pago"
export const MEDIOS_ENTREGA = "entrega"
export const REGISTRO = "registro"
export const TRAKER = "seguimiento"
export const DATOS_USUARIO = "datos"
export const DATOS_PEDIDO = "pedidos"
//ABM
export const ABM_SHOPS = "tiendas";
export const ABM_PRODUCTS = "productos";
export const ABM_SERVICES = "servicios";
export const ABM_ACADEMIC_OFFER = "oferta-academica";
export const ABM_PROFESSIONALS = "profesionales";
export const ABM_PROMOTIONS = "promociones";
export const ABM_CATEGORIES = "categorias";
export const ABM_ACCESSORIES = "accesorios"
export const ABM_SECTORS = "sectores"
export const ABM_CONFIGURATION = "configuracion"
export const ABM_ORDER = "orden"

