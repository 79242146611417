import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";

const SubcategoriesTable = (props) => {
  const { subcategories, onClick } = props;

  return (
    <div style={{ height: 350 }}>
      <Table className="text-center">
        <thead>
          <tr>
            <th style={{ width: 100 }}>Nombre</th>
            <th style={{ width: 100 }}>Categoría asociada</th>
            <th style={{ width: 100 }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {typeof subcategories !== "undefined" &&
          subcategories.length !== 0 &&
          subcategories !== null ? (
            subcategories.map((category) => (
              <tr key={category.id}>
                <td style={{ width: 50, textAlign: 'center' }}>{category.name}</td>
                <td style={{ width: 50, textAlign: 'center' }}>
                  {category.parentCategory && category.parentCategory["name"]}
                </td>
                <td>
                  <Row className="justify-content-center">
                      <Col xs={3}>
                          <Button
                              variant="secondary"
                              onClick={() => onClick(category, "edit")}
                          >
                              <BsPencilSquare size={22} />
                          </Button>
                      </Col>
                      <Col xs={3}>
                          <Button
                              variant="danger"
                              onClick={() => onClick(category, "delete")}
                          >
                              <BsTrash size={22} />
                          </Button>
                      </Col>
                  </Row>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No hay subcategorías</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default SubcategoriesTable;
