import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import "./css/trakerPage.css"
import { ProgressBarApp } from './ProgressBar';

export default function TrakerPage() {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const [response, setResponse] = useState(false)
    const data = {
        'numeroDeTracking': '360000044179430',
        'contrato': '300006611',
        'ciclo': 'Distribution',
        'estado': 'Pendiente',
        'estadoId': 21,
        'fechaEstado': '2021-03-09T11:59:04',
        'sucursalDeDistribucion': {
            'nomenclatura': 'MONSERRAT',
            'descripcion': 'Monserrat',
            'id': 12
        },
        'fechaCreacion': '2021-03-10T11:18:01',
        'destino': {
            'Postal': {
                'localidad': 'C.A.B.A.',
                'pais': 'Argentina',
                'direccion': 'AV J MANUEL DE ROSAS 380',
                'codigoPostal': '1002'
            }
        },
        'remitente': {},
        'destinatario': {
            'nombreYApellido': 'Juana Gonzalez',
            'tipoYNumeroDeDocumento': 'PAS783297632',
            'eMail': 'destinatario@andreani.com'
        },
        'bultos': [
            {
                'kilos': 0.005,
                'valorDeclaradoConImpuestos': 1452,
                'IdDeProducto': '123456789',
                'volumen': 0.000005
            }
        ],
        'idDeProducto': '123456789',
        'referencias': [
            '360000044179430',
            '2',
            'B',
            '123456789'
        ]
    }
    const enviarInfo = (values) => {
        console.log(values);
        setResponse(true)
    }

    return (
        <div className='traker-container'>
            <form className='traker-form' onSubmit={handleSubmit(enviarInfo)}>
                <input
                    type='text'
                    placeholder='introduce el codigo de seguimiento'
                    className='input'
                    {...register("codigo")}
                ></input>
                <input type="submit" className='submit-button' />
            </form>
            {response &&
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <td>estado</td>
                                <td>fecha estado</td>
                                <td>fecha creacion</td>
                                <td>sucursal de distribucion</td>
                                <td>destino</td>
                                <td>destinatario</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.estado}</td>
                                <td>{data.fechaEstado}</td>
                                <td>{data.fechaCreacion}</td>
                                <td>{data.sucursalDeDistribucion.descripcion}</td>
                                <td>{data.destino.Postal.direccion}</td>
                                <td>{data.destinatario.nombreYApellido}</td>
                            </tr>
                        </tbody>
                    </table>
                    <ProgressBarApp ciclo={data.ciclo}></ProgressBarApp>
                </div>}
        </div>
    )
}
