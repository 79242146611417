import { useState, useEffect, useContext } from "react";
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";
import { Sections } from "../../components/layout/Sections";

export const Abm = ({
  title = "",
  breadcrumb = [
    { caption: "Tiendas", href: "#" },
    { caption: "Administrar", href: "#" },
  ],
  tabs,
  PopupDelete,
  keyState
}) => {
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  const [activeKey, setActiveKey] = keyState;

  useEffect(() => {
    setHeaderTitle(title);
    setBreadcrumb(breadcrumb);
    handleSelectTab(activeKey);
  }, [breadcrumb])

  const handleSelectTab = (key) => {
    tabs.find(tab => tab.key === key)?.onSelect();
    setActiveKey(key);
  }

  return (
    <>
      {PopupDelete}
      <Sections tabs={tabs} activeKey={activeKey} onSelectTab={handleSelectTab} />
    </>
  );
}