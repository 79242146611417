import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import DatosUsuario from '../DatosUsuario'

export default function PaginaDatosView() {
    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <DatosUsuario></DatosUsuario>
            <Footer></Footer>
        </>
    )
}