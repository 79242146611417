import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./ControlTags.css";
var objectPath = require("object-path");

export const ControlTagsSelect = ({
  name,
  label,
  placeholder = "Ingrese un tag",
  onChange = () => {},
  data,
  start,
  end,
  options
}) => {
  const value = data ? objectPath(data).get(name, "") : "";

  const [input, setInput] = useState("");
  const [tags, setTags] = useState(() => (value ? value.split(",") : []));
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  useEffect(() => {
    onChange && onChange({ target: { name, value: tags.join(",") } });
  }, [tags]);

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const handleInputChange = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    setInput(value);
    const trimmedInput = value  .trim();

   
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
   
  };

  return (
    <div className="control-tags mb-3 row mb-3 row w-100 d-flex flex-row">
      <label htmlFor={name} className="col-sm-4 col-form-label fst-italic">
        {label}
      </label>

      <div
        className={`container col-sm-8 d-flex flex-row p-0`}
        style={{ backgroundColor: "white" }}
      >
       <Form.Select
            data-live-search="true"
            id={name}
            name={name}
            onChange={handleInputChange}
            value={value}
            className="flex-grow-1 h-100"
            style={{ border: "0px solid white" }}
          >
           <option value="">{placeholder}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
      
          </Form.Select>
        {start}
        {tags.map((tag, index) => (
          <div className="tag">
            {tag}
            <button onClick={() => deleteTag(index)}>x</button>
          </div>
        ))}
        {end}
      </div>
    </div>
  );
};
