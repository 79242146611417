import React, { useState, useEffect } from 'react';
import "./css/datosUsuario.css"

const DatosEditable = ({ id, label, value, onChange }) => (
    <div>
        <h4 className="gris-subtitle">{label}</h4>
        <input
            className="datos"
            type="text"
            id={`input-${id}`}
            value={value}
            onChange={onChange}
        />
    </div>
);

const DatosNoEditable = ({ label, value }) => (
    <div>
        <h4 className="gris-subtitle">{label}</h4>
        <p className="datos">{value}</p>
    </div>
);

const DatosPersonales = ({ data, editable, handleEditField, handleInputChange }) => {
    const camposMostrados = [
        { label: 'Nombre', field: 'firstName' },
        { label: 'DNI', field: 'identificationNumber' },
        { label: 'Correo Electrónico', field: 'email' },
        { label: 'Fecha de Nacimiento', field: 'birthDate' },
        { label: 'Código Profesional', field: 'tuCodigoProfesional' },
        { label: 'Usos de Código', field: 'usosCodigo' }
    ];

    return (
        <div className="personales-container" >
            <div className='datos-title-container'>
                <h3 className="datos-title">DATOS PERSONALES</h3>
                <button onClick={() => handleEditField('personales')}>
                    {editable ? 'Guardar' : 'Editar'}
                </button>
            </div>
            <div className="personales">
                {camposMostrados.map(({ label, field }) => (
                    <div key={field}>
                        {field === 'tuCodigoProfesional' || field === 'usosCodigo' ? (
                            <DatosNoEditable
                                label={label}
                                value={data[field]}
                            />
                        ) : (
                            editable ? (
                                <DatosEditable
                                    id={field}
                                    label={label}
                                    value={data[field]}
                                    onChange={(e) => handleInputChange('personales', field, e)}
                                />
                            ) : (
                                <DatosNoEditable
                                    label={label}
                                    // value={field === "Contraseña" || field === "Credencial" ? "****" : data[field]} // Si es Contraseña o Credencial, se muestra "****"
                                    value={data[field]}
                                />
                            )
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
const DatosUbicacion = ({ data, editable, handleEditField, handleInputChange }) => {
    const camposUbicacionMostrados = [
        { label: 'Calle', field: 'street' },
        { label: 'Número Externo', field: 'externalNumber' },
        { label: 'Código Postal', field: 'zipCode' },
        { label: 'País', field: 'country', defaultValue: 'Argentina' },
        { label: 'Provincia', field: 'province' },
        { label: 'Ciudad', field: 'city' },
        { label: 'Localidad', field: 'town' }
    ];
    const getPaisValue = () => {
        // Si el valor de país en los datos es null, retornamos el valor por defecto 'Argentina'
        return data.country !== null ? data.country : 'Argentina';
    };

    return (
        <div className="personales-container">
            <div className='datos-title-container'>
                <h3 className="datos-title">DATOS DE UBICACIÓN</h3>
                <button onClick={() => handleEditField('location')}>
                    {editable ? 'Guardar' : 'Editar'}
                </button>
            </div>
            <div className="ubicacion">
                {camposUbicacionMostrados.map(({ label, field }) => (
                    <div key={field}>
                        {editable ? (
                            <DatosEditable
                                id={field}
                                label={label}
                                value={field === 'country' ? getPaisValue() : data[field]}
                                onChange={(e) => handleInputChange('location', field, e)}
                            />
                        ) : (
                            <DatosNoEditable
                                label={label}
                                value={field === 'country' ? getPaisValue() : data[field]}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};


export default function DatosUsuario() {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_URL_BACKEND;
    const email = localStorage.getItem("email");
    const [personales, setPersonales] = useState({});
    const [location, setLocation] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}/users/${email}`, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data && data.location) {
                    const { location, ...datosPersonales } = data;
                    setPersonales({ ...datosPersonales, editable: false });
                    setLocation({ ...location, editable: false });
                } else {
                    console.error('Location not found in data');
                }

            } catch (error) {
                console.error('Error fetching data:', error);

            }
        };

        fetchData();
    }, [token]);

    const handleEditField = (section) => {
        if (section === 'personales') {
            setPersonales(prevState => ({ ...prevState, editable: !prevState.editable }));
        } else if (section === 'location') {
            setLocation(prevState => ({ ...prevState, editable: !prevState.editable }));
        }
    };

    const handleInputChange = (section, fieldName, event) => {
        const { value } = event.target;
        if (section === 'personales') {
            setPersonales(prevState => ({ ...prevState, [fieldName]: value }));
        } else if (section === 'location') {
            setLocation(prevState => ({ ...prevState, [fieldName]: value }));
        }
    };

    return (
        <div className="datos-container">
            <p className="section-area">EXCLUSIVO PROFESIONAL</p>
            <h2 className="title-datos">Mis datos</h2>
            <DatosPersonales
                data={personales}
                editable={personales.editable}
                handleEditField={() => handleEditField('personales')}
                handleInputChange={(fieldName, e) => handleInputChange('personales', fieldName, e)}
            />
            <DatosUbicacion
                data={location}
                editable={location.editable}
                handleEditField={() => handleEditField('location')}
                handleInputChange={(fieldName, e) => handleInputChange('location', fieldName, e)}
            />
        </div>
    );
}

