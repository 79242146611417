import './HomePage.css'
import Footer from './components/Footer'
import Main from './components/Main'
import Nav2 from './components/Nav2'
import Header from './components/Header'

function HomePage() {

  return (
    <>
      <div className='header-container'>
        <Header></Header>
        <Nav2></Nav2>
      </div>
      <Main></Main>
      <Footer></Footer>
    </>
  )
}

export default HomePage
