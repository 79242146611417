import { Button, Modal } from "react-bootstrap";

function ModalDelete(props) {
  const { title, children, show, close, remove } = props;

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => remove()}>
          Si
        </Button>
        <Button variant="secondary" onClick={close}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDelete;
