import React from "react";
import { useState } from "react";
import { Row } from "react-bootstrap"
import { create } from "../../../services/abm";
import { ControlText } from "../../helpers";
import { AbmForm } from "../AbmForm";

const AddSectors = ({item, schema, onCancel, onConfirm, confirmLabel = "Guardar", cancelLabel = "Cancelar", title, errorState}) => {
     const [validated, setValidated] = useState(false);
    
       const [newSector, setNewSector] = useState({
         name: "",
       });

       const handleSubmit = (e) => {
        e.preventDefault();
         const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        } else {
         add({
             name: newSector.name
           });
         }
         setNewSector("");
         setValidated(true);
       };

     const add = (newSector) => {
        console.log(newSector);
         create(newSector, "sectors");
       };        

    return( <AbmForm onSubmit={onConfirm} schema={schema} item={item} onCancel={onCancel} confirmLabel={confirmLabel} cancelLabel={cancelLabel} errorState={errorState}>
        {({onControlChange, entity, entityErrors}) => (
          <>
          <Row>
                <ControlText name="name" label="Título" errors={entityErrors} data={entity} onChange={onControlChange} placeholder={""} required />
          </Row>
        </>
      )}
      </AbmForm>
        
    )
}

export default AddSectors;