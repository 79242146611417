import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import EstadoPedido from '../EstadoPedido'


export default function PaginaPedidoView() {
    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <EstadoPedido></EstadoPedido>
            <Footer></Footer>
        </>
    )
}