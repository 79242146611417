import { React, useContext, useEffect } from "react";
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";

const GenericOptionDisable = ({text}) => {

    const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
    const breadcrumb = [
        { caption: text, href: "#" },
    ]

    useEffect(()=>{
        setBreadcrumb(breadcrumb);
        setHeaderTitle(text);
        }, [text])
    
    const divStyle = {
        display: "flex",
        margin: "auto",
    };

    return (<div style={divStyle}>Opción inhabilitada</div>)
}

export default GenericOptionDisable;