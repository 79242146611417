import { TRAKER } from "../../../config/router/routes"
import "./css/datosPedido.css"
import { ShoppingCart } from "../assets/icons"

export default function DatosPedido() {
    const ordenes = [
        {
            "ordenNumero": 1234,
            "fecha": "20/07/2022",
            "productos": [
                { "codigo": 12345, "nombre": "Gentle Milk Cleanser", "precio": 2610, "cantidad": 1 },
                { "codigo": 23456, "nombre": "Purifying Gel Cleanser", "precio": 1540, "cantidad": 1 },
                { "codigo": 56789, "nombre": "Exfoliating Scrub", "precio": 4500, "cantidad": 3 }
            ],
            "recargoPorEnvio": 500,
            "total": 7850
        },
        {
            "ordenNumero": 1221,
            "fecha": "21/07/2022",
            "productos": [
                { "codigo": 12347, "nombre": "Vitamin C Foundation", "precio": 2196, "cantidad": 1, "tono": "Almond" },
                { "codigo": 12346, "nombre": "Vitamin C Foundation", "precio": 2196, "cantidad": 1, "tono": "Cocoa" }
            ],
            "total": 4383
        }
    ]
    // const ordenes = null
    return (
        <div className="datos-container">
            <p className="section-area">EXCLUSIVO PROFESIONAL</p>
            <h2 className="title-datos">Mis pedidos</h2>
            {
                ordenes === null ?
                    <div className="empty-container-pedidos">
                        <ShoppingCart width={50}></ShoppingCart>
                        <p>Aún no tienes pedidos realizados</p>
                        <p className="section-area">¡Mira nuestras promociones!</p>
                    </div>

                    : <div>
                        {ordenes?.map((orden) => (
                            <div className="personales-container" key={orden.ordenNumero}>
                                <div className="orden-header">
                                    <p className="section-area">ORDEN #{orden.ordenNumero}</p>
                                    <p className="gris-subtitle"> {orden.fecha} </p>
                                </div>
                                {
                                    orden?.productos.map((product) => (
                                        <div className="productos-container" key={product.codigo}> {/* Utilizar product.codigo como clave */}
                                            <p className="gris-subtitle"> {product.codigo} </p>
                                            <p className="gris-subtitle"> {product.nombre} </p>
                                            <p className="gris-precio"> ${product.precio} ARS </p>
                                            <p className="gris-cantidad"> {product.cantidad}u </p>
                                            <p className="gris-subtitle"> {product.tono} </p>
                                        </div>
                                    ))
                                }
                                {
                                    orden.recargoPorEnvio ?
                                        <div className="envio-container">
                                            <p className="gris-subtitle"> recargoPorEnvio </p>
                                            <p className="gris-envio"> ${orden.recargoPorEnvio} ARS </p>
                                        </div>
                                        : ""
                                }
                                <div className="pedidos-container">
                                    <p className="total"> Total {orden.total} ARS </p>
                                    <a href={TRAKER} className="seguimiento-button">Ver seguimiento</a>
                                </div>

                            </div>
                        ))}
                    </div>
            }



        </div>
    )
}