import "../components/css/paginaProductos.css"
import { ImgContainer5 } from "./Slider5"
import { ShoppingCart } from "../assets/icons"
import Filters from "./Filters"
import { SliderNav } from "./SliderNav"
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

export default function PaginaProductos({ productos }) {
    const categoryNav = ["Todos", "Ácido Hialurónico", "Ácido Ferrulico", "Vitamina C", "Retinol", "Osmo-Hidratación",
        "Todos", "Ácido Hialurónico", "Ácido Ferrulico", "Vitamina C", "Retinol", "Osmo-Hidratación"]
    //search
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const searchQuery = query.get("search") || "";

    const filteredProducts = productos.filter(
        (product) =>
            product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.categorie.toLowerCase().includes(searchQuery.toLowerCase())
    );
    //promos
    const { id } = useParams();
    const productIds = parseInt(id);
    console.log(productos);
    return (
        <div className="pagina-productos-container">
            <Filters></Filters>
            <div className="pagina-productos">
                <h2>Productos Faciales: Activos Específicos</h2>
                <SliderNav navCategory={categoryNav}></SliderNav>
                {filteredProducts.length > 0 ?
                    <div className="productos">
                        {productos.map((producto, index) => {
                            return (
                                <div key={index}>
                                    <ImgContainer5 imagen={producto.image} description={producto.categorie} precio={producto.price} product={producto}></ImgContainer5>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="productos">
                        <p> No se encontraron productos que coincidan con tu busqueda</p>
                    </div>
                }
            </div>
            <div className="button-container">
            </div>
        </div>
    )
}
