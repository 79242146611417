import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { FiltersProvider } from "./components/home-page/components/context/FiltersContext";
import "./assets/scss/bootstrap.scss";
import { CartProvider } from "./components/home-page/components/context/CartContext";
import { LogInProvider } from "./components/home-page/components/context/LogInContext";


ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <LogInProvider>
        <FiltersProvider>
          <CartProvider>
            <App />
          </CartProvider>
        </FiltersProvider>
      </LogInProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
