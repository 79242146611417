import { useCart } from "./hooks/useCart"
import "./css/cart.css"
import "./css/asideCart.css"
import { ShoppingCart } from "../assets/icons"
import { useState } from "react"
import { Link } from "react-router-dom"
import { CHECKOUT } from "../../../config/router/routes"

export function AsideCart() {
    const { cart, addToCart, removeFromCart, restToCart } = useCart()
    let totalCost = 0
    let envio = 600

    return (
        <aside className='aside-cart'>
            <ul>
                {cart.length === 0 ? (
                    <li className="empty-container"> {/* Aquí se cambió de div a li */}
                        <p>Tu carrito está vacío</p>
                        <p className="p-2">¡Mira nuestras promociones!</p>
                    </li>
                ) : (
                    cart.map(product => (
                        <CartItem
                            key={product.id}
                            addToCart={() => addToCart(product)}
                            removeFromCart={() => removeFromCart(product)}
                            restToCart={() => restToCart(product)}
                            {...product}
                            {...totalCost += (product.price * product.quantity)}
                        />
                    ))
                )}
            </ul>
            <footer className="subtotal-container">
                <div className="subtotal">
                    <strong> SUBTOTAL </strong>
                    <strong>$ {totalCost.toFixed(2)} ARS </strong>
                </div>
                <div className="subtotal">
                    <strong> COSTO DE ENVÍO </strong>
                    <strong>$ {envio.toFixed(2)} ARS </strong>
                </div>
                <div className="total">
                    <strong> Total </strong>
                    <strong>$ {(totalCost + envio).toFixed(2)} ARS </strong>
                </div>
            </footer>
        </aside>
    )
}


function CartItem({ image, price, title, quantity, subtitle, addToCart, removeFromCart, restToCart }) {

    function titleTrim(title) {
        const titleArray = title.split(" ")
        const titleTrim = titleArray.slice(0, 3)
        const result = titleTrim.join(" ")
        return result
    }

    return (
        <li>
            {<img className="item-img" src={`data:image/png;base64,${image[0]?.base64}`} alt={title}></img>}
            <div>
                <div className="buttonx-container">
                    <button className="buttonx" onClick={removeFromCart}>x</button>
                </div>
                <h2 className='cart-title'>
                    {titleTrim(title)}
                </h2>
                <h3 className="cart-subtitle"> {subtitle} </h3>

                <footer className="item-footer">
                    <strong className="price"> $ {price} ARS </strong>
                    <div>
                        <button onClick={restToCart}>-</button>
                        <strong> {quantity} </strong>
                        <button onClick={addToCart}>+</button>
                    </div>
                </footer>
            </div>
        </li>
    )
}

export default function Cart() {

    const { cart, addToCart, removeFromCart, restToCart } = useCart()
    const [openCart, setOpenCart] = useState(false)

    const handleCart = () => {
        if (openCart === false) {
            setOpenCart(true)
        }
        else {
            setOpenCart(false)
        }
    }
    let totalCost = 0

    return (
        <>
            <button className="cart-button" onClick={handleCart}>
                <ShoppingCart width={12}></ShoppingCart> Ver carrito </button>

            {openCart &&

                <aside className='cart'>
                    <div className="cart-header">
                        <div className="cart-title-container">
                            <ShoppingCart width={16}></ShoppingCart>
                            <h2> CARRITO </h2>
                        </div>
                        <button onClick={handleCart}> atras </button>
                    </div>

                    <ul>
                        {cart.length === 0 ?
                            <div className="empty-container">
                                <p>Tu carrito esta vacio</p>
                                <p className="p-2">!Mira nuestras promociones!</p>
                            </div>
                            : cart.map(product => (
                                <CartItem
                                    key={product.id}
                                    addToCart={() => addToCart(product)}
                                    removeFromCart={() => removeFromCart(product)}
                                    restToCart={() => restToCart(product)}
                                    {...product}
                                    {...totalCost += (product.price * product.quantity)}
                                />
                            ))
                        }
                    </ul>
                    <footer className="subtotal-container">
                        <div className="subtotal">
                            <strong> SUBTOTAL </strong>
                            <strong>$ {totalCost.toFixed(2)} ARS </strong>
                        </div>
                        {/* <button className="button"><Link to={`/${CHECKOUT}`}>IR AL CHECKOUT</Link></button> */}
                        <a className="button" href={`/${CHECKOUT}`}>IR AL CHECKOUT</a>
                        <button className="button" onClick={handleCart}> SEGUIR COMPRANDO </button>

                    </footer>
                </aside>
            }
        </>
    )
}
