/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import PaginaProductos from '../PaginaProductos'
import { useFilters } from '../hooks/useFilters'

export default function PaginaProductosViews() {
    const { filterProducts, mapedProducts } = useFilters()
    const filteredProducts = filterProducts(mapedProducts)

    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <PaginaProductos productos={filteredProducts}></PaginaProductos>
            <Footer></Footer>
        </>
    )
}
