import Header from '../Header'
import Nav2 from '../Nav2'
import Footer from '../Footer'
import PaginaCheckout from '../PaginaCheckout'

export default function PaginaCheckoutView() {

    return (
        <>
            <div className='header-container'>
                <Header></Header>
                <Nav2></Nav2>
            </div>
            <PaginaCheckout></PaginaCheckout>
            <Footer></Footer>
        </>
    )
}
