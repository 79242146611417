// // /* eslint-disable react/prop-types */
// import React, { useEffect, useState } from "react";
// import { useParams } from 'react-router-dom';
// import SvgBack from "../assets/Back";
// import SvgNext from "../assets/Next";
// import SliderColors from "./SliderColors";
// import "./css/paginaProducto1.css";
// import "./css/imgContainerProduct.css";
// import { useCart } from "./hooks/useCart";

// function ImgContainerProduct({ image }) {
//     return (
//         <div className="img-container-product">
//             <div className="secondary-container" >
//                 {Array.isArray(image) && image.map((imageItem, index) => {
//                     return (
//                         <div key={index} className="img-container-2">
//                             {
//                                 imageItem.description === "galeria" && (
//                                     <img src={`data:image/png;base64,${imageItem.base64}`} alt={`imagen ${imageItem.description}`} className="secondary-image" />
//                                 )
//                             }
//                         </div>

//                     );
//                 })}
//             </div>

//             {
//                 Array.isArray(image) && image.map((imageItem, index) => (
//                     <div key={index} className="main-img-container">
//                         {
//                             imageItem.description === "principal" &&
//                             <img src={`data:image/png;base64,${imageItem?.base64}`} className="big-img" alt={`imagen ${imageItem.description}`} />
//                         }
//                     </div>
//                 ))
//             }
//         </div>
//     );
// }

// export default function PaginaProducto1({ product }) {
//     const [cantidad, setCantidad] = useState(1);
//     const { id } = useParams();
//     // const productID = parseInt(id);
//     const [productID, setproductID] = useState(parseInt(id))
//     const { addToCart } = useCart();
//     console.log(product);
    
    
//     useEffect(() => {
//         // console.log(`ID del producto seleccionado: ${id}`);
//         setproductID(15)
//     }, [id]);

//     function increaseCount() {
//         setCantidad(cantidad + 1);
//     }

//     function reduceCount() {
//         if (cantidad > 1) {
//             setCantidad(cantidad - 1);
//         }
//     }

//     const producto = product.filter((p) => {
//         return p.id === productID
//     });

//     function addToCartWithQuantity() {
//         addToCart({
//             ...producto[0],
//             quantity: cantidad
//         });
//     }

//     return (
//         <>
//             <div className="product-container">
//                 <div className="img-container">
//                     <ImgContainerProduct image={producto[0]?.image} />
//                     <p>Sin parabenos</p>
//                 </div>

//                 <div className="info-container">
//                     {product.map((product, index) => (
//                         product?.id === productID && (
//                             <div key={index} className="info">
//                                 <div className="info-header">
//                                     <p className="categoria">{product.parentCategoryName} <span className="negrita">/</span> {product.categorie} </p>
//                                     <p>COD 17026</p>
//                                 </div>
//                                 <h2>{product.title}</h2>
//                                 <h3>{product.subtitle}</h3>
//                                 <p>{product.description}</p>
//                                 <p><span className="negrita">modo de uso:</span> {product.description}</p>
//                                 <p className="price">$ {product.price} ARS</p>
//                                 <SliderColors variant={product.variants}></SliderColors>
//                             </div>
//                         )
//                     ))}
//                     <div className="zona-de-compra">
//                         <input className="contador" type="text" value={cantidad} readOnly />
//                         <div className="button-container">
//                             <button className="button-color" onClick={increaseCount}><SvgBack height={14} width={14} fill={"red"} /></button>
//                             <button className="button-color" onClick={reduceCount}><SvgNext height={14} width={14} fill={"red"} /></button>
//                         </div>
//                         <button onClick={addToCartWithQuantity} className="button">AÑADIR AL CARRITO</button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import SvgBack from "../assets/Back";
import SvgNext from "../assets/Next";
import SliderColors from "./SliderColors";
import "./css/paginaProducto1.css";
import "./css/imgContainerProduct.css";
import { useCart } from "./hooks/useCart";
import { getOne } from "../../../services/abm";

function ImgContainerProduct({ image }) {
    return (
        <div className="img-container-product">
            <div className="secondary-container">
                {Array.isArray(image) && image.map((imageItem, index) => (
                    <div key={index} className="img-container-2">
                        {imageItem.description === "galeria" && (
                            <img src={`data:image/png;base64,${imageItem.base64}`} alt={`imagen ${imageItem.description}`} className="secondary-image" />
                        )}
                    </div>
                ))}
            </div>
            {Array.isArray(image) && image.map((imageItem, index) => (
                <div key={index} className="main-img-container">
                    {imageItem.description === "principal" && (
                        <img src={`data:image/png;base64,${imageItem?.base64}`} className="big-img" alt={`imagen ${imageItem.description}`} />
                    )}
                </div>
            ))}
        </div>
    );
}

export default function PaginaProducto1(
    // { product }
) {
    const [cantidad, setCantidad] = useState(1);
    const { id, variantID } = useParams(); // Obtener variantID de la URL
    const [product, setProduct] = useState()
    const [isloaded, setLoaded] = useState(false)
    // console.log(variantID);
    const get = async()=>{
        const product = await getOne(id,"products") 
        setProduct(product)
        setLoaded(true)
        console.log(product);
        console.log(product?.variants[0]);
    } 
    
    useEffect(() => {
        get()
    }, [])
    
    const [productID, setProductID] = useState(parseInt(id));
    const [selectedVariantID, setSelectedVariantID] = useState(parseInt(variantID)); // Estado para variantID
    const { addToCart } = useCart();

    // useEffect(() => {
    //     setProductID(parseInt(id));
    //     setSelectedVariantID(parseInt(variantID)); // Actualizar el variantID en el estado
    // }, []);

    function increaseCount() {
        setCantidad(cantidad + 1);
    }

    function reduceCount() {
        if (cantidad > 1) {
            setCantidad(cantidad - 1);
        }
    }

    // const producto = product.find((p) => p.id === productID);
    // const selectedVariant = producto?.variants?.find(v => v.id === selectedVariantID); Buscar la variante seleccionada
    const selectedVariant = product?.variants?.find(v => v.id === selectedVariantID);
    function addToCartWithQuantity() {
        addToCart({
            // ...producto,
            ...product,
            selectedVariant, // Pasar la variante seleccionada
            quantity: cantidad
        });
    }

    // Determinar qué título, precio, etc. mostrar
    const displayTitle = selectedVariant?.title || product?.purpose?.primaryTitle    
    const displayPrice = selectedVariant?.publicPrice || product?.purpose?.publicPrice
    const displayCode = selectedVariant?.code || product?.purpose?.code
    const displayDescription = selectedVariant?.description || product?.purpose?.description


    return (
        <>
          {
            isloaded &&
            <div className="product-container">
            <div className="img-container">
                    <ImgContainerProduct image={product?.photos} />
                    <p>Sin parabenos</p>
                </div>

                <div className="info-container">
                    {/* {product?.map((product, index) => (
                        product?.id === productID && ( */}
                            <div className="info">
                                <div className="info-header">
                                    <p className="categoria">{product?.categories[0]?.parentCategory?.name} <span className="negrita">/</span> {product?.categories[0].name} </p>
                                    <p>COD {displayCode || 'No disponible'}</p>
                                </div>
                                <h2>{displayTitle}</h2> {/* Mostrar título de la variante o del producto */}
                                <h3>{product?.purpose?.secondaryTitle}</h3>
                                <p>{displayDescription}</p> {/* Mostrar descripción */}
                                <p><span className="negrita">Modo de uso:</span> {product?.purpose?.description}</p>
                                <p className="price">$ {displayPrice} ARS</p> {/* Mostrar precio */}
                                <SliderColors variant={product?.variants}></SliderColors>
                            </div>
                        {/* )
                    ))} */}
                    <div className="zona-de-compra">
                        <input className="contador" type="text" value={cantidad} readOnly />
                        <div className="button-container">
                            <button className="button-color" onClick={increaseCount}><SvgBack height={14} width={14} fill={"red"} /></button>
                            <button className="button-color" onClick={reduceCount}><SvgNext height={14} width={14} fill={"red"} /></button>
                        </div>
                        <button onClick={addToCartWithQuantity} className="button">AÑADIR AL CARRITO</button>
                    </div>
                </div>
            </div>        
          }
            
        </>
    );
}
