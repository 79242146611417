import { createContext, useReducer } from "react";
import { cartReducer, cartInitialState } from "../reducers/cart";

export const CartContext = createContext()

function useCartReducer() {

  const [state, dispatch] = useReducer(cartReducer, cartInitialState)

  const addToCart = product => dispatch({
    type: 'ADD_TO_CART',
    payload: product
  })
  // dispatch({
  //   type: "ADD_TO_CART",
  //   payload: {
  //     id: productId,
  //     quantity: desiredQuantity
  //   }
  // });

  const restToCart = product => dispatch({
    type: 'REDUCE_FROM_CART',
    payload: product
  })

  const removeFromCart = product => dispatch({
    type: 'REMOVE_FROM_CART',
    payload: product
  })

  const clearCart = () => dispatch({ type: 'CLEAR_CART' })

  return { state, addToCart, removeFromCart, clearCart, restToCart }
}

export function CartProvider({ children }) {

  const { state, addToCart, removeFromCart, clearCart, restToCart } = useCartReducer()

  return <CartContext.Provider value={{
    cart: state,
    addToCart,
    clearCart,
    restToCart,
    removeFromCart
  }}>
    {children}
  </CartContext.Provider>
}