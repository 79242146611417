import { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAbm } from "../../services/abm";
import "../../css/promotions.css";
import { PrivateLayoutContext } from "../../components/layout/PrivateLayout";
// import AddAccessories from "../../components/abm/accessories/AddAccessories";
import * as yup from 'yup';
import { getAll } from "../../services/abm";
import ManageSectors from "../../components/abm/sectors/mangeSectors";
import AddSectors from "../../components/abm/sectors/addSectors";

let schemaAdd = yup.object().shape({
  name: yup.string().required("Título es un campo obligatorio."),
});

function Sectores() {

  const { create } = useAbm({entity: "sectors"});
  const [key, setKey] = useState('manage');
  const [sectors, setSectors] = useState([]);
  const [error, setError] = useState(null);
  const { setHeaderTitle, setBreadcrumb } = useContext(PrivateLayoutContext);
  const administrarBreadcrumb = [
    { caption: "Sectores", href: "#" },
    { caption: "Administrar", href: "#" }
  ];

  const aniadirBreadcrumb = [
    { caption: "Sectores", href: "#" },
    { caption: "Añadir", href: "#" }
  ];


  const handleAddConfirm = (data) => {
    console.log('data', data);
    const body = {
      name: data.name,
    }
    create(body).then(result => {
      setKey("manage");
    }).catch(error=>{
      console.log(error)
      setError(error.headers.get("message") || "Error agregando un sector");
    });
  } 

  useEffect(() => {
    setBreadcrumb(administrarBreadcrumb);
    setHeaderTitle('Sectores');
  }, []);
  
  useEffect(() => {
    getAll("sectors")
      .then((res) => {
        setSectors(res);
      })
      .catch((err) => console.log(err));
    if (key === "add") setBreadcrumb(aniadirBreadcrumb);
    else setBreadcrumb(administrarBreadcrumb);
  }, [key]);

  return (
    <Container className="container-accessories">
      <header className="header-container">
        <h1 className="title">Sectores</h1>
      </header>
      <main>
        <section>
          <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
              <Tab eventKey="manage" title="Administrar">
                <ManageSectors sectors={sectors}/>
              </Tab>
          
              <Tab eventKey="add" title="Añadir">
                <AddSectors
                  schema={schemaAdd}
                  accessories={sectors}
                  onConfirm={handleAddConfirm}
                />
              </Tab>
          </Tabs>
        </section>
      </main>
      <footer className="footer">
        <p>Copyright &copy; 2022 <strong>Idraet Group</strong> Todos los derechos reservados</p>
      </footer>
    </Container>
  );
}

export default Sectores;
