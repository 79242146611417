/* eslint-disable default-case */
export const cartInitialState = JSON.parse(window.localStorage.getItem("cart")) || []

// actualizar el local storage antes de un return
export const updateLocalStorage = state => {
    window.localStorage.setItem("cart", JSON.stringify(state))
}

export const cartReducer = (state, action) => {

    const { type: actionType, payload: actionPayload } = action

    switch (actionType) {
        case "ADD_TO_CART": {
            const { id, quantity = 1 } = actionPayload; // Default quantity to 1 if not provided
            const productCartIndex = state.findIndex((item) => item.id === id);

            if (productCartIndex >= 0) {
                const newState = [...state];
                newState[productCartIndex].quantity += quantity;
                updateLocalStorage(newState);
                return newState;
            }

            const newState = [...state, { ...actionPayload, quantity }];
            updateLocalStorage(newState);
            return newState;
        }

        case "REMOVE_FROM_CART": {
            const { id } = actionPayload
            const newState = state.filter(item => item.id !== id)
            updateLocalStorage(newState)
            return newState
        }

        case "CLEAR_CART": {
            updateLocalStorage([])
            return []
        }
    }

    return state
}