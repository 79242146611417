import { 
    Row, 
    Col, 
    Form
} from 'react-bootstrap';

function Step3({formData, setFormData}){
    return(
        <div>
            <Row>
                <Col>
                    <Form.Group as={Col} className="mb-3" controlId="formBasicInstagram">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="" 
                        required  
                        name="instagram"
                        value={formData.instagram}
                        onChange={(event) => 
                            setFormData({...formData, instagram: event.target.value})
                            }
                        />
                        <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3" controlId="formBasicFacebook">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="" 
                        required  
                        name="facebook"
                        value={formData.facebook}
                        onChange={(event) => 
                            setFormData({...formData, facebook: event.target.value})
                            } 
                        />
                        <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Hora de Inicio</Form.Label>
                    <Form.Select 
                    className="mb-3" 
                    aria-label="Seleccionar" 
                    required  
                    name="horaInicio"
                    value={formData.horaInicio}
                    onChange={(event) => 
                        setFormData({...formData, horaInicio: event.target.value})
                        }
                    >
                        <option value="">Seleccioná un horario de inicio</option>
                        <option value="00:00:00">00:00</option>
                            <option value="01:00:00">01:00</option>
                            <option value="02:00:00">02:00</option>
                            <option value="03:00:00">03:00</option>
                            <option value="04:00:00">04:00</option>
                            <option value="05:00:00">05:00</option>
                            <option value="06:00:00">06:00</option>
                            <option value="07:00:00">07:00</option>
                            <option value="08:00:00">08:00</option>
                            <option value="09:00:00">09:00</option>
                            <option value="10:00:00">10:00</option>
                            <option value="11:00:00">11:00</option>
                            <option value="12:00:00">12:00</option>
                            <option value="13:00:00">13:00</option>
                            <option value="14:00:00">14:00</option>
                            <option value="15:00:00">15:00</option>
                            <option value="16:00:00">16:00</option>
                            <option value="17:00:00">17:00</option>
                            <option value="18:00:00">18:00</option>
                            <option value="19:00:00">19:00</option>
                            <option value="20:00:00">20:00</option>
                            <option value="21:00:00">21:00</option>
                            <option value="22:00:00">21:00</option>
                            <option value="23:00:00">23:00</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>

                <Col>
                    <Form.Label>Hora de Cierre</Form.Label>
                    <Form.Select 
                    className="mb-3"  
                    aria-label="Seleccionar" 
                    required  
                    name="horaCierre"
                    value={formData.horaCierre}
                    onChange={(event) => 
                        setFormData({...formData, horaCierre: event.target.value})
                        }
                    >
                        <option  value="">Seleccioná un horario de cierre</option>
                        <option value="00:00:00">00:00</option>
                        <option value="01:00:00">01:00</option>
                        <option value="02:00:00">02:00</option>
                        <option value="03:00:00">03:00</option>
                        <option value="04:00:00">04:00</option>
                        <option value="05:00:00">05:00</option>
                        <option value="06:00:00">06:00</option>
                        <option value="07:00:00">07:00</option>
                        <option value="08:00:00">08:00</option>
                        <option value="09:00:00">09:00</option>
                        <option value="10:00:00">10:00</option>
                        <option value="11:00:00">11:00</option>
                        <option value="12:00:00">12:00</option>
                        <option value="13:00:00">13:00</option>
                        <option value="14:00:00">14:00</option>
                        <option value="15:00:00">15:00</option>
                        <option value="16:00:00">16:00</option>
                        <option value="17:00:00">17:00</option>
                        <option value="18:00:00">18:00</option>
                        <option value="19:00:00">19:00</option>
                        <option value="20:00:00">20:00</option>
                        <option value="21:00:00">21:00</option>
                        <option value="22:00:00">21:00</option>
                        <option value="23:00:00">23:00</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Acepto tarjeta de crédito</Form.Label>
                    <Form.Select 
                    aria-label="Seleccionar" 
                    required  
                    name="aceptoCredito"
                    value={formData.aceptoCredito}
                    onChange={(event) => 
                        setFormData({...formData, aceptoCredito: event.target.value})
                        }
                    >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>

                <Col>
                    <Form.Label>Acepto tarjeta de débito</Form.Label>
                    <Form.Select 
                    aria-label="Seleccionar" 
                    required  
                    name="aceptoDebito"
                    value={formData.aceptoDegito}
                    onChange={(event) => 
                        setFormData({...formData, aceptoDebito: event.target.value})
                        }
                    >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Deseo figurar como punto de venta</Form.Label>
                    <Form.Select 
                    aria-label="Seleccionar" 
                    required  
                    name="puntoVenta"
                    value={formData.puntoVenta}
                    onChange={(event) => 
                        setFormData({...formData, puntoVenta: event.target.value})
                        }
                    >
                        <option>Si</option>
                        <option value="1">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>

                <Col>
                    <Form.Label>Deseo recibir novedades por mail</Form.Label>
                    <Form.Select
                    aria-label="Seleccionar" 
                    required  
                    name="novedadesMail"
                    value={formData.novedadesMail}
                    onChange={(event) => 
                        setFormData({...formData, novedadesMail: event.target.value})
                        }
                    >
                        <option>Si</option>
                        <option value="1">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Requerido</Form.Control.Feedback>
                </Col>
            </Row>
        </div>
    )
}

export default Step3;