import { useRef, useState } from 'react';
import { RemoveIcon } from '../abm/stores/RemoveIcon';

export const ControlFile = ({
  label,
  id,
  multiple,
  optional,
  onChange,
  onRemove,
  data,
  filesNames,
  start,
  end,
  errors = [],
  ...options
}) => {

  const [fileNames, setFileNames] = useState(filesNames || []);
  const input = useRef();
  const removeFile = () => {
    onRemove();
    setFileNames([]);
    input.current.value = null;
    onChange({
      target: { name: id, value: [] },
    });
  };

  const handleChange = async (e) => {
    if (input.current) {
      const filesList = [];
      const filesBlobs = [];
      const files = Array.from(input.current.files);
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i].name);
        let reader = new FileReader();
        reader.onload = (e) => {
          filesBlobs.push(e.target.result);
        };
        await reader.readAsDataURL(files[i]);
      }
      onChange({
        target: { name: id, value: input.current.files },
      });
      setFileNames(filesList);
    }
  };

  return (
    <div className="container">
      <div className="mb-3 row w-100 d-flex flex-row">
        {label && <label htmlFor={''} className="col-sm-4 col-form-label fst-italic">
          {label}
        </label>}
        <div className={`${label ? 'col-sm-8' : 'col-sm-12'} d-flex flex-row p-0 align-items-center position-relative bg-primary`}>
          <label htmlFor={id} className="col-sm-4 d-flex cursor-pointer">
            <div className="p-3 bg-primary text-white col-sm-12">
              Subir archivo
            </div>
          </label>
          <div className="files-container bg-white col-sm-8 row">
            {fileNames.length === 0 && 'No hay archivos'}
            {fileNames.map((fileName, index) => (
              <span
                key={index}
                className={
                  'text-truncate me-1 d-flex align-items-center mb-1' +
                  (multiple ? ' multiple-file col-sm-3 col-lg-12' : 'col-sm-12')
                }
              >
                {fileName}
              </span>
            ))}
          </div>
          {fileNames.length > 0 && <RemoveIcon action={removeFile} />}
          <input
            ref={input}
            className="d-none flex-grow-1 form-control p-3 m-0 bg-white"
            type="file"
            id={id}
            multiple={multiple}
            onChange={handleChange}
          />
        </div>
        {optional && (
          <label className="col-sm-8 offset-sm-4 mt-2 fst-italic">
            * Opcional
          </label>
        )}
      </div>
    </div>
  );
};
