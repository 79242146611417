import React from "react";
import { useState, useEffect } from "react";
import PromotionsTable from "./PromotionsTable";
import ModalEdit from "../../../components/abm/promotions/ModalEdit";
import ModalDelete from "../../../components/modals/ModalDelete";
import { update, remove } from "../../../services/abm";

function ManagePromotions({ promotions, categories, getPromotionsAndCategories }) {
  const [promocionSeleccionada, setPromocionSeleccionada] = useState({
    id: 0,
    name: "",
    percentage: 0,
    startDate: "",
    endDate: "",
    relations: [],
  });
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [promotionsToShow, setPromotionsToShow] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState([]);

  const seleccionarPromocion = (element, caso) => {
    setPromocionSeleccionada(element);
    if (caso === "Editar") setShowModalEdit(true);
    else setShowModalDelete(true);
  };
  const handleCloseModalEdit = () => setShowModalEdit(false);
  const handleCloseModalDelete = () => setShowModalDelete(false);

  const edit = (body) => update(body, promocionSeleccionada.id, "promotions", "PUT");

  const removePromotion = () => remove(promocionSeleccionada.id, "promotions");

  const searchPromotion = (value) => {
    const valueLowerCase = value.toLowerCase();
    if (value.length === 0) return cancelSearchPromotion();
    const filteredPromotions = promotions.filter(item => item?.title?.toLowerCase().includes(valueLowerCase) || item?.code?.toLowerCase().includes(valueLowerCase));
    setPromotionsToShow(filteredPromotions);
  }

  const cancelSearchPromotion = () => getPromotionsAndCategories();

  useEffect(() => {
    setPromotionsToShow(promotions);
    setCategoriesToShow(categories);
  }, [promotions]);

  return (
    <div>
      <PromotionsTable
        promotions={promotionsToShow}
        onClick={seleccionarPromocion}
        searchPromotion={searchPromotion}
        cancelSearchPromotion={cancelSearchPromotion}
      />

      <ModalEdit
        promocionSeleccionada={promocionSeleccionada}
        setPromocionSeleccionada={setPromocionSeleccionada}
        categories={categoriesToShow}
        show={showModalEdit}
        close={handleCloseModalEdit}
        edit={edit}
      />

      <ModalDelete
        title="promoción"
        show={showModalDelete}
        close={handleCloseModalDelete}
        remove={removePromotion}
      >
        {`¿Estás seguro/a que querés eliminar la promoción ${promocionSeleccionada.title}?`}
      </ModalDelete>
    </div>
  );
}

export default ManagePromotions;