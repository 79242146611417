const urlApiGeo = "https://apis.datos.gob.ar/georef/api/";

const server = process.env.REACT_APP_URL_BACKEND;

const routes = {
  signup: `${server}/auth/signup`,
  login: `${server}/auth/authorize`,
};

export { urlApiGeo, routes, server };
