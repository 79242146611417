// import React, { useState } from 'react';
// import './css/FileUpload.css';

// export const FileUpload = ({ register }) => {

//     const [selectedFile, setSelectedFile] = useState(null);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         setSelectedFile(file);
//     };

//     const handleFileRemove = () => {
//         setSelectedFile(null);
//     };

//     return (
//         <div className="file-upload-container">
//             <input type="file" onChange={handleFileChange} style={{ display: 'none' }} {...register("image")} />
//             <button className='button-add' onClick={() => document.querySelector('.file-upload-container input[type="file"]').click()}>+</button>
//             {selectedFile && (
//                 <div className="selected-file">
//                     <p className='file'>{selectedFile.name}</p>
//                 </div>
//             )}
//             <button className='buttonx' onClick={handleFileRemove}>X</button>
//         </div>

//     );
// };

import React, { useState } from 'react';
import './css/FileUpload.css';

export const FileUpload = ({ register }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleFileRemove = () => {
        setSelectedFile(null);
    };

    return (
        <div className="file-upload-container">
            <input
                type="file"
                onChange={(e) => {
                    handleFileChange(e);
                    register("image", { required: true }); // Registrar el campo en el formulario
                }}
                style={{ display: 'none' }}
            />
            <button className='button-add' onClick={() => document.querySelector('.file-upload-container input[type="file"]').click()}>
                +
            </button>
            {selectedFile && (
                <div className="selected-file">
                    <p className='file'>{selectedFile.name}</p>
                </div>
            )}
            <button className='buttonx' onClick={handleFileRemove}>X</button>
        </div>
    );
};

