import { IdraetIcon, ProfesionalHeaderIcon, SearchIcon, ShoppingCart, ShoppingCartRemove, WhatsappIcon } from "../assets/icons"
import Cart from "./Cart"
import { LoginModal } from "./LogIn";
import SearchBar from "./SearchBar";
import "./css/header.css"
import "./css/menuDesplegableHeader.css"
import { UseAuth } from "./hooks/UseAuth";

const MenuDesplegableHeader = ({ handleAuth }) => {

    return (
        <div className="dropdown">
            <a className="professionalAccess"> <ProfesionalHeaderIcon width={12} fill={"#ff5299"}></ProfesionalHeaderIcon> Hola Renzo! </a>
            <div className="dropdown-content-triangulo">
                <div className="triangulo-header-1"></div>
            </div>
            <div className="dropdown-content">
                <a href="/datos">Mis datos</a>
                <a href="/pedidos">Mis pedidos</a>
                <a href="#" onClick={handleAuth}>Cerrar sesión</a>
            </div>
        </div>
    )
}

export default function Header() {
    const { isOpen, Auth, openModal, closeModal, handleAuth } = UseAuth()
    return (
        <header className="header">
            <a href="/landing" className="idraet-icon"> <IdraetIcon width={220}></IdraetIcon> </a>
            <div className="header-centro">
                <SearchBar></SearchBar>
                {/* <div className="buscador-container">
                    <input className="buscador" placeholder='buscar'></input>
                    <div className="borde"> <SearchIcon fill={"var(--icon-color)"} width={16} height={16}></SearchIcon></div>
                </div> */}
                {
                    Auth ?
                        <>
                            <MenuDesplegableHeader handleAuth={handleAuth}></MenuDesplegableHeader>
                        </>
                        :
                        <>
                            <a className="professionalAccess" onClick={openModal}> <ProfesionalHeaderIcon width={12} fill={"#ff5299"}></ProfesionalHeaderIcon> Acceso profesional</a>
                            <LoginModal isOpen={isOpen} openModal={openModal} closeModal={closeModal} handleAuth={handleAuth}></LoginModal>
                        </>
                }
                <a className="shoppingCart">
                    <Cart></Cart>
                </a>
            </div>
            <a className="whatsapp-icon"> <div> <WhatsappIcon fill={"var(--icon-color)"} width={16} height={16}></WhatsappIcon> </div> <span>Tienda</span> Caballito</a>
        </header>
    )
}
