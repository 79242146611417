import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { RiUser3Fill } from "react-icons/ri"
import { ImKey } from "react-icons/im"
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const PopupDelete = ({title, show, onClose, showLogin, message, onConfirm, onCancel}, namespace) => {

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Modal show={show} onHide={onClose} onExit={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5">
        <div style={{width: "100%"}}>
          <Container>
            <Row className="py-4">
              <Col>
                {message}
              </Col>
            </Row>
            {showLogin &&     
              <>
                <Row className="pb-4">
                  <Col>
                    Identificá tu cuenta para continuar
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label for="user" className="form-label" style={{fontWeight: 600}}>Usuario:</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text"><RiUser3Fill color="var(--bs-secondary)" /> </span>
                      <input  type="text" className="form-control" placeholder="Usuario" aria-label="Username" id="user" onChange={(e) => setUser(e.target.value)} value={user} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <label for="password" className="form-label" style={{fontWeight: 600}}>Contraseña:</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text"><ImKey color="var(--bs-secondary)" /></span>
                      <input type={showPassword ? "text" : "password"} className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                      <span className="input-group-text" style={{backgroundColor: "transparent", cursor: "pointer"}} onClick={()=>setShowPassword(currentValue => !currentValue)}>
                        {showPassword 
                          ? <FaEyeSlash color="var(--bs-primary)" size={20}/>
                          : <FaEye color="var(--bs-primary)" size={20}/>
                        }
                      </span>
                    </div>
                  </Col>
                </Row>
              </>     
            }


          </Container>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" style={{color:"white", borderRadius: 0}} onClick={()=>onConfirm({user, password})}>Eliminar</Button>
        <Button variant="light" style={{border:"#f2f2f2 2px solid", borderRadius: 0}} onClick={onCancel}>Cancelar</Button>
      </Modal.Footer>
      
    </Modal>
  )
}
