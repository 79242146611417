import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";

const CategoriesTable = (props) => {
  const { categories, onClick } = props;

  return (
    <div style={{ height: 350 }}>
      <Table className="text-center">
        <thead>
          <tr>
            <th style={{ width: 100 }}>Nombre</th>
            <th style={{ width: 100 }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            typeof categories !== "undefined" &&
              categories.length !== 0 &&
              categories !== null ? (
              categories.map((category) => (
                <tr key={category.id}>
                  <td style={{ width: 50, textAlign: 'center' }}>{category.name}</td>
                  <td>
                    <Row className="justify-content-center">
                        <Col xs={2}>
                            <Button
                                variant="secondary"
                                onClick={() => onClick(category, "edit")}
                            >
                                <BsPencilSquare size={22} />
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <Button
                                variant="danger"
                                onClick={() => onClick(category, "delete")}
                            >
                                <BsTrash size={22} />
                            </Button>
                        </Col>
                    </Row>
                </td>

                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={4}>No hay categorías</td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  );
};

export default CategoriesTable;
