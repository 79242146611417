import React, { useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
const objectPath = require("object-path");

export const SimpleControlText = ({
  name,
  label,
  placeholder,
  disabled,
  onChange,
  data,
  start,
  end,
  errors = [],
  ...options
}) => {
  const target = useRef();
  const value = data ? objectPath(data).get(name, "") : "";
  const error = errors.find((e) => e.path === name);

  return (
    <>
      <div
        className={`d-flex flex-row p-0 ${disabled ? "disable-input" : ""}`}
        style={{
          backgroundColor: "white",
          minHeight: "35px",
          alignItems: "center",
        }}
      >
        {start}
        <input
          type="text"
          id={name}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          {...options}
          className={"flex-grow-1 h-100 px-2 w-100"}
          style={{ border: "0px solid white" }}
          disabled={disabled}
        />
        {end}
      </div>
      <Overlay target={target.current} show={error} placement="right">
        {(props) => (
          <Tooltip
            id={`tooltip-${name}`}
            {...{ ...props, style: { ...(props.style || {}), opacity: 0.8 } }}
          >
            {error?.message}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
