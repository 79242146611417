import React from "react";
import { useState, useEffect } from "react";

import { getAll, update, remove } from "../../../services/abm";
import SectorsTable from "./sectorsTable";
import ModalEdit from "./modalEdit";
import ModalDelete from "../../modals/ModalDelete";

function ManageSectors(props){

  const { sectors } = props;
  const [sectorSeleccionada, setSectorSeleccionada] = useState({
      id: 0,
      name: "",
  });
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const seleccionarsector = (element, caso) => {
    setSectorSeleccionada(element);
    if (caso === "Editar") setShowModalEdit(true);
    else setShowModalDelete(true);
  };
  const handleCloseModalEdit = () => setShowModalEdit(false);
  const handleCloseModalDelete = () => setShowModalDelete(false);
  const edit = (body) =>  update(body, sectorSeleccionada.id, "sectors", "PUT");
  const removeAccessory = () => remove(sectorSeleccionada.name, "sectors");


 
  return(
      <div>
          <SectorsTable
              sectors={sectors}
              onClick={seleccionarsector}
          />

          <ModalEdit
              accesorioSeleccionada={sectorSeleccionada}
              setAccesorioSeleccionada={setSectorSeleccionada}
              show={showModalEdit}
              close={handleCloseModalEdit}
              edit={edit}
          />

          <ModalDelete
              title="sector"
              show={showModalDelete}
              close={handleCloseModalDelete}
              remove={removeAccessory}
          >
              {`¿Estás seguro/a que querés eliminar la promoción ${sectorSeleccionada.name}?`}
          </ModalDelete>
      </div>
  )
}

export default ManageSectors;