import "./css/footer.css"
import { CardIcon, FbIcon, InstaIcon, PromotionFooterIcon, ShieldIcon } from "../assets/icons"

export default function Footer() {
    return (
        <footer className="footer-container">
            <section className="footer footer-1">

                <div className="footer-icon-container">
                    <div className="footer-icon">
                        <CardIcon width={60}></CardIcon>
                    </div>
                    <p>Métodos de pago</p>
                </div>
                <div className="footer-icon-container">
                    <div className="footer-icon">
                        <PromotionFooterIcon width={60} fill={"var(--icon-color)"}></PromotionFooterIcon>
                    </div>
                    <p>Promociones todos los meses</p>
                </div>
                <div className="footer-icon-container">
                    <div className="footer-icon icon-3">
                        <ShieldIcon width={60}></ShieldIcon>
                    </div>
                    <p>Sitio seguro</p>
                </div>

            </section>
            <section className="footer footer-2">
                <p>Términos & Condiciones</p>
                <p>Ayuda</p>
                <p>Defensa al Consumidor</p>
                <p>Cancelar compra</p>
            </section>
            <section className="footer footer-3">
                <p>Copyright &#169;  <span style={{color: 'white'}}>IdraetGroup</span></p>
                <div>
                    <a className="insta-icon"> <FbIcon width={25} height={25} fill={"#F2509C"}></FbIcon> </a>
                    <a className="insta-icon"><InstaIcon width={25} height={25} fill={"#F2509C"}></InstaIcon></a>
                </div>
                <p>Carlos Calvo 2967, Carapachay - Buenos Aires</p>
            </section>
        </footer>
    )
}
