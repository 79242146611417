import { useState } from 'react';
import { ProfesionalHeaderIcon } from '../assets/icons';
import styles from './login.module.css';
import { useForm } from "react-hook-form";
import { useAppContext } from '../../../App';
import { routes } from '../../../config/constants';
import { REGISTRO } from '../../../config/router/routes';

export const LoginModal = ({ isOpen, openModal, closeModal, handleAuth }) => {
    const [showPassword, setShowPassword] = useState(false);
    const appContext = useAppContext();

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const handleBackdropClick = (e) => {
        if (!e.target.closest(`.${styles.modalContent}`)) {
            closeModal();
        }
    };
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const enviarInfo = async (values) => {
        console.log(values);
        const url = routes.login;
        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json", "Content-Type": "application/json"
            },
            body: JSON.stringify(values),
        });

        try {
            if (!response.ok) {
                throw new Error();
            }
            const resObj = await response.json();
            appContext.signIn(resObj.access_token, resObj.sector_id, resObj.city, resObj.namespace, resObj.role);
            handleAuth()
        } catch (err) {
            console.log(`[ERROR - login publico - 105] ${err}`);
        }
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.modalWrapper} onClick={handleBackdropClick}>
                    <form className={styles.modal} onSubmit={handleSubmit(enviarInfo)} onClick={handleModalClick}>
                        <button className={styles.modalCloseButton} onClick={closeModal}> X </button>
                        <h2 className={styles.title}>Acceso para profesionales</h2>
                        <p className={styles.subText}>¡Ingresá tus datos de Idraet Professional VIP y accedé a los mejores beneficios de nuestra tienda!</p>
                        <div className={styles.inputContainer}>
                            <label className={styles.label}>Usuario</label>
                            <div className={styles.iconInputContainer}>
                                <div className={styles.iconContainer}><ProfesionalHeaderIcon width={14} fill={"#100402"}></ProfesionalHeaderIcon></div>
                                <input className={styles.input}
                                    placeholder='Email'
                                    {...register("email")}
                                ></input>
                            </div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className={styles.labelContainer}>
                                <label className={styles.label}>Contraseña</label>
                                <a href={REGISTRO} className={styles.links}>¿Olvidaste tu contraseña?</a>
                            </div>
                            <div className={styles.iconInputContainer}>
                                <div className={styles.iconContainer}><ProfesionalHeaderIcon width={14} fill={"#100402"}></ProfesionalHeaderIcon></div>
                                <input className={styles.input}
                                    type={showPassword ? "text" : "password"}
                                    id="password" name="password"
                                    {...register("password")}
                                ></input>
                                <button type="button" className={styles.buttonPassword} onClick={togglePasswordVisibility}>
                                    {showPassword ? "Ocultar" : "Mostrar"}
                                </button>
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <button className={styles.buttonPrimary} type='submit'>INICIAR SESIÓN</button>
                            <button className={styles.buttonSecondary}>INGRESAR CON IDRAET VIP</button>
                        </div>
                        <p className={styles.linksContainer}>¿No sos usuario? <a href={REGISTRO} className={styles.links}> ¡Registrate aqui!</a></p>
                    </form>
                </div>
            )}
        </div>
    );
};
