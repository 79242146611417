import React from "react";
import { Table, Row, Col, Button } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import { AiOutlineSearch, AiOutlineUnorderedList } from "react-icons/ai"
import "../../../css/promotions.css";
import { server } from "../../../config/constants";

const PromotionsTable = (props) => {
  const { promotions, onClick, searchPromotion, cancelSearchPromotion } = props;

  return (
    <div className="promotions-table-container">
      <Table className="table-container promotions-table">
        <div className="search-container">
          <input placeholder="Buscar por título o código" className="search" onChange={(e) => searchPromotion(e.target.value)} />
          <AiOutlineSearch className="search-icon" size={22} />
        </div>
        {/* <Button className="filter">
            <div>
                <AiOutlineUnorderedList size={18} className="list-icon"/>
                <p>filtrar</p>
            </div>
        </Button> */}
        <thead>
          <tr>
            <th>IMAGEN</th>
            <th>TÍTULO</th>
            <th>TIPO</th>
            <th>PORCENTAJE</th>
            <th>DESDE</th>
            <th>HASTA</th>
            <th>CÓDIGO</th>
          </tr>
        </thead>
        <tbody>
          {typeof promotions != "undefined" &&
            promotions.length !== 0 &&
            promotions != null ? (
            promotions.map((element) => (
              <tr key={Math.random()}>
                <td className="img-promotion-td">
                  <div className="img-promotion-container">
                    {element.img ? (
                      <img
                        src={URL.createObjectURL(element.img)}
                        className="p-0"
                        alt="Imagen principal"
                        style={{ objectFit: "contain" }}
                      />
                    ) : null}
                  </div>
                </td>
                <td>{element.title}</td>
                <td>{element.type.id}</td>
                <td>{element.percentage}</td>
                <td>{element.startDate.substring(10, 0)}</td>
                <td>{element.endDate.substring(10, 0)}</td>
                <td>{element.code}</td>
                <td className="container-btn-actions">
                  <Row className="justify-content-center">
                    <Col xs={4} className="text-center">
                      <Button
                        onClick={() => onClick(element, "Eliminar")}
                        className="btn-action btn-editar"
                      >
                        <TiDelete size={26} className="icon icon-delete" />
                        <p>Eliminar</p>
                      </Button>
                    </Col>
                  </Row>
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td colSpan={6}>No hay promociones</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default PromotionsTable;
